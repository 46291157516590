export const musicSheet = [
    ["A Simple Wish", "f l k [j6] 0 e r t k l x [k0] r u I o k v C [x8] w t y u C v n C Q r y I", "z x [c9] e y x [x0] r [zu] l k [l6] 0 e r t j k [lq] t i z [kw] y [hi] [j6] 0 e r t\nl k [j6] 0 e r t k l x [k0] r u I o k v C [x8] w t y u C v n C Q r y I\nz x [c9] e y x [x0] r [zu] l k [l6] 0 e r t j k [lq] t i z [kw] y [hi] [j6] 0 e r t\nl k [j6] 0 [ke] [l0] [k5] 9 [hw] 9 [jq] [ht] [hi] [gt] [h0]\n[lu] k [j5] 0 [ke] [lt] [x0] r [ku] o [jq] [kt] [lw] [hy] [j6] 0 e r t\nk l [x8] w [zt] [lw] [z5] 9 w r [c9] e [xy] [ze] [x6] [f0] [je] [kt] [lq] t [ki] [lq] [z5] 9 [hw] 9 [x8] [zw] [lt] [zw] [x0]\nk l [x8] w [zt] [lu] [zw] y o [c9] e [xy] [zi] [x6] [f0] [jt] k [lq] t [ki] [lq] [zw] k [ho] w [je] u p a s\n[ht] [ji] [xt] [zw] [yo] q [k0] [lr] [zu] [ko] [l6] 0 e h [gq] [ht] [ji] [xq] [zw] k [zo] [zw] [x0]\nk l [x8] w [zt] [lu] [zw] y o [c9] e [xy] [zi] [x6] [f0] [jt] k [lq] t [ki] [lq] [zw] k [ho] w [je] u p a s"],
    ["Alicia Keys - Fallino", "d D [f0] r u u r 0 9 e y y [eh] [9f]\n[0h] r u u r 0 9 e [yh] [yj] e [9h]\n[0k] r [uk] u r 0 9 [ed] [yd] [yd] [ed] D\n[0f] r u u r 0 9 e [yf] G [ef] G\n[0h] r u u r 0 9 e [yf] [yd] [ed] D\n[0f] r u u r 0 9 e y [yj] [ej] [9k]\n[0j] r [uh] u r 0 9 e [yh] [yh] [ef] [9d]\n[0f] r u u r 0 [9a] [ed] [yf] G e [9h]\n[3h] 3 [70] k [3h] [2j] h 2 [79]\n[2d] [3f] 3 [70] h [3f] [2h]\n[2a] [79d] f G [3h] [3f] [70]\nk [3h] [2j] h 2 [79] d D\n[3f] 3 [79] h [3f] [2h] z x\n[0x] r u u r 0 9 e y y e 9\n0 r u u r 0 9 e y y e 9 0"],
    ["Amelie", "[d9] [qey] s [a6] [80e]\np [d9] [qey] f d s a s [a6] [80e]\np [sq] [et] a [u8] [0r] [sq] [et] d s a s a [u8] [0r]\n[dz9] [qey] [sl] [ak6] [80e]\n[pj] [dz9] [qey] f d s a s [ak6] [80e]\n[pj] [sql] [et] [ak] [uf8] [0r] [sql] [et] d s a s a [uf8] [0r]\nfuiuiu dui sui ayuyuy ptyuytfuiuiu dui sui ayuyuy ptyuyt"],
    ["Andrea Bocelli - Time To Say Goodbye", "[y5] [owr] 2 | I p [o3] [owr] [o1] [wtu]\np [a5] [wry] 2 | [peq] s a [o3] [wry]\n[ho] [GI] [dy] [ho] [GI] [dy] [ar] [dy5] [wry]\n[pu1] s a [pit] o I | t y u y u I u I [ot] I o p o u p a [soy] a\n[tw] y u y [ue2] I u I [ot3] I o p [oy4] u p a [soy] a\n[oye] I u I | [sou] [aou] [utw]\nu I [yrw] [rw] | u y [yeq] y [eq]\no I [yrw] y y [yrw] y a [sou] o [oye] I [yrw] y [rw]\nu y [yeq] [oy] I p [yr] [yrw]\np a [su1] o o [oye] I [y5] [oyr] 2\nI p [o3] [yrw] 1 [utw]\np [a5] a [yrw] 2 [peq] s a [o3] [yrw]\n[ho] [GI] [dy] [ho] [GI] [dy] [ar] [dy5] [yrw]\n[pue] s a [pit] o I [y5] o [oyr] 2\nI p [o3] [yrw] 1 [utw] | p [a5] a a [ayr] 2 a [peq] s a [o3] [yrw]\n[ho] [GI] [dy] [ho] [GI] [dy] [ar] [dy5] [yrw]\n[pu1] s a [pyt] o I [yt2]\nt y u y u I u I [ot] I o p o u p a [soy] a\n[tw1] y u y [ue2] I u I [put] o [ay2]\n[tw1] y u y [ue2] I u I [our] [ao] I [ou]\n[tw3] y u y [ue2] I u I [put] o [py2] o [sou] a [yeq]\n[dy5] [pu1] s a [pt2] o I [u6] p [peu] 3\nO a p [qeu] [qey] | a S S S [ST] 3 S [ar0] d S p [ueq]\n[jp] [HO] [fu] [jp] [HO] [fu] [ST] [fu6] [eu]\na d S [ay3] p S [u6] | p [peu] 3 O a p [ueq] [ey]\na S S S [ST] 3 S [ar0] d S [p4]\n[eu] [jp] [HO] [fu] [jp] [HO] [fu] [ST] [fu6] [ue]\nG H j | [yeq] [urw] [utw] [iye] 6"],
    ["Angry Birds", "dfg d j dfg d j\njJjhg gfd\ndfg d ghj g\njklklklkjhj\ndfg d ghj g\njklklklkjhj\njkllll lzlkl\nlll l lzlkj"],
    ["Antony Gleize Lullaby", "4y 4u 4u 4i\n5i 5u 5u 5y\n6t 6y 6y 6u 6u 6y 6t 6r 6e\n4e 4e 4e 4e\n5e 5e 5e 5e 5e 5e 5e 5w 5w 5w\n6e 6e 6e 6e 6e 6e 6e 6e 6e 6r 6t 6y 6t\n4e 4e 4e 4e\n5e 5e 5e 5e 5e 5e 5w 5w 5w\n6e 6e 6e 6e 6e 6e 6e 6e 6u 6u 6u 6u 6y 6t\n4e 4r 4t 4r 4r 4e\n5e 5r 5t 5y 5t 5r 5e\n6e 6r 6t 6r 6r 6e\n6e 6r 6t 6y 6t 6r 6e\n4 y 4 u 4 u 4 i\n5 i 5 u 5 u 5 y\n6t 6y 6y 6u 6u 6y 6t 6r 6e\n4 e 4 e 4 e 4 e\n5 e 5 e 5 e 5 e 5 e 5 e 5 e 5 w 5 w 5 w\n6 e 6 e 6 e 6 e 6 e 6 e 6 e 6 e 6 e 6 r 6 t 6 y 6 t\n4 e 4 e 4 e 4 e\n5 e 5 e 5 e 5 e 5 e 5 e 5 w 5 w 5 w\n6 e 6 e 6 e 6 e 6 e 6 e 6 e 6 e 6 u 6 u 6 u 6 u 6y 6t\n4 e 4 r 4 t 4 r 4 r 4 e\n5 e 5 r 5 t 5 y 5 t 5 r 5 e\n6 e 6 r 6 t 6 r 6 r 6 e\n6 e 6 r 6 t 6 y 6 t, 6 r, 6 e"],
    ["Avatar - I See You", "[6f] 0 d [ef] s | [5d] 9 s [wd] a | [4s] 8 [qp] |\n[6f] 0 d [ef] h | [5d] 9 s [wd] a | [4s] 8 [qj] |\nh g [6f] [0d] [ef] | h [5d] [9s] [wd] |\na [4s] 8 [qp] | [2i] 6 9 | o p [3o] 7 0 |\np a [4p] 8 q | a s [5a] 9 [wd] |\nh [6s] 0 u p [es] | [5d] 9 o a [wd] |\nf [2G] 6 [9h] | G [3f] 7 0 |\n[6s] 0 u p [es] | [5d] 9 o a [wd] | f [2G] 6 [9h] |\nj [3k] 7 0"],
    ["Avril - When You're Gone", "Ghf Ghf Ghf Ghb Ghf Ghf Ghf Ghb Ghf Ghf Ghf Ghz dhGdp\nryryyyuIo uapouu uapop yryrtyuIoo uuapouu uaoppoI\nrttoooutrrpo opasasappp\nosa yaaaa appop osa yaaaa appop osa sasasaaappoIoo yyyap\noIo IouIouIouIod IouIouIouIod IouIouIouIod dphGdp\nryryyyuIo uapouu uapop yryrtyuIoo uuapouu uaoppoI\nrttoooutrrpo opasasappp\nosa yaaaa appop osa yaaaa appop osa sasasaaappoIoo yyyap\noIo IIIIppo ooppo aaap asa aPaPaPaPaPa apapapapasa aaaaaaaaapodd osa yaaaa appop\nosa yaaaa appop oaasasasadaadaopoyu yyyap oIo\nGhf Ghf Ghf Ghb Ghf Ghf Ghf Ghz dhGdp"],
    ["Bak Postaci Geliyor", "ytyuytr etrer ytyuytr etrew"],
    ["Baris Manco - Gulpembe", "f kjk f kjk\nf kk klkjk\njhj hjkj hGh GhjhGh GfG fGh gfdf\ngfdf kjk gfdf jhj gfdf hGh gfdf\nh gf h gf h gfGff\nfGhjk jzllkjk\ndGhjhGd\ndGhjkjhG Ghf\nGhf Ghf\ndGhjhGd\ndGhjkjhG Ghf\nGhf Ghf"],
    ["Basto - Again and Again", "[u0] I o [p2] a [f8] [f8] [f8] [f8] [f8]\nd [a5] s a [p2] a [o3] [o3] [o3] [o3] p o I\n[u0] I o [p2] a [f8] [f8] [f8] [f8]\nd f G [h8] G f [d2] a [f0] [f0] [f0] [f0]"],
    ["Batman - The Dark Knight", "[ql] [ts] J s h s H s J s g\nl s J s h s H s J\n[ql] [ts] J s h s H s J s g\n[ti O] | [o Y] | s\n[qti O] | [wo Y] | s\n[qtisg] | [YPH] h\n[isg O] | [YH] h\n[ig TO] | [toh] g [8wtiog] [uf]\nH h g [OS] % q %\n[PD] [dy] [st] [do] | J\n[HO] [ho] g [STO]\nt | D | s | [TP]\nq | w | W | [WO6] | [89wtyo]"],
    ["Batman Begins - Barbastella", "J j J h | h z l z | h l J l | h G f G\nd J j J | h D d D | d j h j | j z c"],
    ["Beethoven - Fifth Symphony", "[ho] [ho] [ho] [DY]\n[gi] [gi] [gi] [dy]\noooY OOOo DDDs\noooy OOOo gggd\nooiY ssdD\nooiY DDgh\n[ho] [ho] [gi] [DY] [yh] [tl]"],
    ["Beethoven - Love Story", "lffll ffllfgfdddkk ddkkdfdsssjj ssjjsdsaaaHH jkgf\nlffll ffllfgfdddkk ddkkdfdsssjj ssjjsdsaaaHH jkHL\nd f p gpgppaa dgd fpsfsd adas dfpsfg opsa sdgf oiuY IpIo\nLggLL ggLLgGgDDDll DDllDgDSSSJJ SSJJSDSsssjj paiu"],
    ["Beethoven - Moonlight Sonata", "[9e]yi eyi eyi eyi | [8e]yi eyi eyi eyi\nEyi Eyi EYo EYo\n[6e]To eyi [6e]yu wTu\n[9q]ey eyi eyi p eyp | [*p]uo euo euo p uop\n[9p]yi eyi P yoE yo | [8p]ti eti [8o]tu s uo\n[4i]ti eti eti eti\nWti Wti Wti O tiO\nOtI OtI OtI O tIO\n* TiW TiW yio yi\n[8o] tYw tYW tyW ty | [5o] tYw tY[wo] ryw ry\n[8t] Yot Yot uosuo | S iO[qt] iOW iO[qa] iO\n[8s] uot uot uosuo | S iO[qt] iOW iO[qa] iO\n[8s] uot uo[6s] YIt YI | s ypt ypE yiE yi\n8 tYW tY* EuwEu\n[9y]wE 9wE [9y]wt [9y]Qe | [5w]Ey Eyo yoP doP d\ndps yps yps dpsd\n[5d]oP yoP 0oP 8oP | *op uop uop [8f]op\n[9g]pdipd [5f]oPd ia | 6Tu pTu PTu oTu\n6Tu eTu ETu wTu\n6ey qey 6wT 0wT\n[26qy] [26qy] [26qy]"],
    ["Beethoven - Ode To Joy", "uuiooiuyttyuu [y y]\nuuiooiuyttyuy [t t]\nyyuty[u i u]ty[u i u]ytyw\nuuiooiuyttyuy [t t]"],
    ["Beethoven - Some Of Fur Elise", "fDfDfads [p6]\n0etup [a7]\n0WuOa [s6]\n0eufDfDfads [p6]\n0etup [a7]\n0WuOsa [p6]\n0easd [f8]\nwtogf [d5]\nwrifd [s6]\n0euds [a3]\n0uufuffxDfDfDfDfDfDfads [p6]\n0etup [a7]\n0WuOa [s6]\n0eufDfDfads [p6]\n0etup [a7]\n0WuOsa [p6]"],
    ["Benim Annem Canim Annem", "sdff fgfd dfg jhgf\nhgdd fgjh ljh fhgf"],
    ["Berkant Akgurgen - Bir Sarkisin Sen", "y u ytr | opoI yuI\nu I uyt | Iouy tyr\no p o I oIou IuIy\no p o I oIou IuIy\nw r y\ny u ytr | opoI yuI I a\nu I uyt | Iouy tyr\no p o I oIou IuIy\no p o I oIou IuIy\ny I p o"],
    ["Beyonce - Halo", "S u p u S u p u S u p u S u a S\nd I a I d I a I d I s I a S a p o p\nS I p S I p S I p a p O p y I y p y I y p y I y a O I u\n0 r T T T r y | e r r r r T e e Q\nQ e e e e e e e r e | 0 e e e e e * 9 *\n0 r T T T T r y | r e r e r e T e e Q\ne e e e e e e r e r T | 0 e e e r r e\ne u u u u I I u\nT y u u u u u p p I\nT y u u T r e Q\nT y u u u u O p a S\np p p p p p a\np p a a a S p p I\np p p p p p p u y T\nu p p p p O p | u p p p p p p p a\np a p a p a S a p p O I\np p p p p p p u y T\np p p p o p\nS a S a S a p p p a S a S a D S p p p a\nS a S a S a p p p a S a S a a p f S"],
    ["Big Bang - Wedding Dress", "h a s f s o s d [fo] f [fo] g | f [fo] d s d |\nh a s f s o s | d [fo] f [fo] g f [fo] d s d |\nh a s f s o s d [fo] f [fo] | g f [fo] d s d |\nh l l l h z k | lh l x c x l l k | l z e u p a sq |\nt i o pt o s d | fw y o p [2x] | o j h g sf sf dp o p o fj fh fs h ad |\n6sh a0 es pft | s o s d fso f g f f d |\n6sh a0 es pft | s o s d fso sfh s oad oad |\n6sh 0sf si si si ip su su su du aod |\nsssss 6sh 0sf si si si ip su su su du ayo |\na a s o psh h g f d d s s p s d sf sf sf sf sf | dg sf sf ad |\nso so so psh h g f | d d s s p s d sf sf sf sf | sf dg fh gj dh |\nad a sp d si si si si si d sf sf h f fh ah | ad a sp d si si si si si d sf | sf ad |\nap a sp d si si si si si si si po osf d f ad h a s f | s o s d |\nf f g f f d | a s h a s f | s o s d | f f g f f d sfh | sf [x2] |\ns [x3] | p o | os [3x] | ad [2x] | s s d s| sfh | sf [2x] | s [3x] | p o | os [3x] | d a"],
    ["Bleach - Morning Remembranceo", "pf lkzlkj pf lkzlkj\nis lkzlkj is lkzlkj\nod lkzlkj od lkzlkj\npf lkzlkj pf lkzlkj\neu sadsap eu sadsap\nqt sadsap qt sadsap\nwy sadsap wy sadsap\neu sadsap eu sadsap\neu sadsap eu sadsap\nqt sadsap qt sadsap\nwy sadsap wy sadsap\neu sadsap eu sadsap\neu sadsap eu sadsap\ne u [sl] [ak] [zd] [sl] [ak] [jp] e u [sl] [ak] [zd] [sl] [ak] [jp]\ne u [sl] [ak] [zd] [sl] [ak] [jp] e u [sl] [ak] [zd] [sl] [ak] [jp]\nq t [sl] [ak] [zd] [sl] [ak] [jp] q t [sl] [ak] [zd] [sl] [ak] [jp]\nw y [sl] [ak] [zd] [sl] [ak] [jp] w y [sl] [ak] [zd] [sl] [ak] [jp]"],
    ["Bryan Adams - Everything I Do", "[8sf] w t w y w\ng h g f [da]\n9 w 9 e 9\nd h h s s\n4 8 q 8 w 8\ns d f s [sd]\no [sd] o [ad]\nf g f d s\nd s a p o\np a s [sf] [sd] a\nf g f d s\nd s a p o o o o\np a s [pg] [sf] o [sf] [ad] [ad]\nf [pg] g f [dg] j [sh] f [pd] [pd]"],
    ["Cahit Berkay - Selvi Boylum Al Yazmalim", "e oIou utuy y yyy rt trrtyree rtyytuyy rttrtyre rettrtyree e"],
    ["Canakkale Icinde Vurdular Beni", "sddd lklj h j k l j h\nsddd lklj h j k l j h\nj jjj h Hhgfghfd fgh gffdd"],
    ["Canon in Do", "[tf] [wd] [es] [0a] [qp] [8o] [qp] [wa]\n[tsf] o [wad] o [eps] u [0oa] u [qip] t [8uo] t [qip] t [woa] y\n[tus] a s [wa] [es] f [0h] j [qg] f d g [8f] d s a [qp] o i u [wy] u i\n[tuo] o [wo] p o i [etu] u [0u] i u y [qt] E e E [8t] t [qt] E e t [wr] t y\n[8wu] [5ry] [6et] [3r] [4e] [1w] [4e] [5t] r [18wtu]"],
    ["Carly Rae Jepsen - Call Me Maybe", "5 a a p a a p a | 3 a a p a a p a | 1 a a p a a p p | 2 p p o o d a\n5 a a p a a p a | 3 a a p a ap a | 1a a p a a p p | 2 p p o o d a\nd d apo | d d apo | d d apo | h h h h h j k | o r y o y\n2 y y y a o | 1 o a s a o | 2 o a p p o | 5 o r y o y\n2 y y a o | 1 o a s a o | 2 o a p p o | 5 o r y o y\n2 y y y a o | 1 o a s a o | 2 o a p p o"],
    ["Chariots of Fire", "s ghjh f s ghjh s ghjh f gfss\nlkj hk hj gh lkj hk\nlkj hk hj gh f gfss\ns [8qeg] h j [80wh] f\ns [8qeg] h j [80wh]\ns [8qeg] h j [80wh] f\nf [8qeg] f s [80ws]\nl k j h [0wrk] h [qetj] g [tuoh]\nl k j h [0wrk]\nl k j h [0wrk] h [qetj] g [80wh] f f [8qeg] f s [80ws]"],
    ["Christina Aguilera - Feel This Momento", "[0etu] [ye9] [5wy] [wt8u] [yrw7] [etu0]\n[ye9] [wry] [utw8] [yrw7] [fso] f x l k [jd] h G h\n[dwoy] d k j [hf] G f [dpy] [sf] f l k [jd] G h j\n[dwyo] d k j [hf] G f [dpy] [sft] t [tl] [tk] [yjd] y y y\n[dwo] [wd] [wdk] j [hfa] G f [dpy] [fst] t [tl] k [jdy] y y y\n[dyw] [wd] [wdk] j [hfu] G [fu] [du]\n[tof]fsp pdddGGhj hhhds fffdd f d\n[tof]fsp pdddGGhj hhhds fffdd f d"],
    ["Chrono Trigger - Peaceful Days", "[8o] i u y [9i] u y t [9t] y u y [qe] [et] [5t] [5r]\n[8w] 0 [wu] 0 [6i] u [wy] t [4o] 6 9 [qy] [5y] 7 9 w\n[8w] 0 [wu] 0 [6i] u [wy] t [3o] 7 0 w r w [rp] a\n[6s] t [ua] [tp] [3o] w u w [qp] t o i [8u] w [7y] w\n[6t] [0r] [wt] [0u] y Q e t [5t] 6 7 9 [wy] q 0 [9o]\n[qp] t o p [5o] r y i [8i] w [qu] y T e [ey] u\n[9yi] e [qu] y [5et] 9 [wy] 9 [8t] w q w 0 w\n[8w] 0 [wu] 0 [6i] u [wy] t [4o] 6 9 [qy] [5y] 7 9 w\n[8w] 0 [wu] 0 [6i] u [wy] t [3o] 7 0 w r w [rp] a\n[6s] t [ua] [tp] [3o] w u w [qp] t o i [8u] w [7y] w\n[6t] [0r] [wt] [0u] y Q e t [5t] 6 7 9 [wy] q 0 [9o]\n[qp] t o p [5o] r y i [8i] w [qu] y T e [ey] u\n[9yi] e [qu] y [5et] 9 [wy] 9 [8t] 0 w q 0 u\n[9yi] e [qu] y [5et] 9 [wy] 9 [8t] 0 w q 0 q 0"],
    ["Cloud Atlas", "[tuo] s e h [rufh] a w h\n[etfh] s 0 h [qufh] s y p\n[tuo] s e h [rufh] a w h\n[etfh] s 0 h [qufh] s y j\n[tufh] z l k [uofh] l k\n[etfj] l z [qegj]\n[tuo] d s a [0wuo] s a\n[etup] d s [woa] [wp]\n[tuo] s e h"],
    ["Coca Cola", "fsdfs\nshgfd\nfsdfd"],
    ["Coldplay - Clocks", "[9d] pI dpI dp\n[6s] pu spu sp\n[6s] pu spu sp\n[0a] ou aou a\nddd dasap\nsss papoy"],
    ["Coldplay - Fix You", "E E o o Y E E Y Y y t |\nE E o o o Y E Y Y Y y |\nE E E o o Y E E Y Y y t |\nE Y i o Y E | E E o o Y E Y Y y t |\nE E oo Y E Y Y y E |\nE E o o o o O o i i Y |\nE Y i o E Y | O o |\ni Y y t E | t Y | O o |\ni Y y r E | t Y | W O o i | Y o Y"],
    ["Daft Punk ft. Pharrell Williams - Get Lucky", "u u a p O p O I\nI a p O p O I\nu a p O p O p | u I\nI a p O p O I\nd | S d G | G H j | j k H\nd | S d G | G H j | j L k\n[ad] [ad] [ad] [ad] d d a f\n[pd] [pd] [pd] [pd] [pd] [pd] [pf]\n[IS] [IS] [IS] [IS] [IS] [IS] f\na a a a a S a p\n[ad] [ad] [ad] [ad] d d a f\n[pd] [pd] [pd] [pd] [pd] [pd] [pf]\n[IS] [IS] [IS] [IS] [IS] [IS] f\na a a a a S a p"],
    ["Demi Lovato - Skyscraper", "opsa | opsa | opsapop |\nopsa | opsa | opsapop | ooGh | ooGf | osapop |\nopsa | opsa | opsapop |\nopsa | opsa | opsapop | ooGh | ooGf | osapop |\nhjhlkjhh | hjhlkjhj | jhjhk | jhjklk |\nhjhlkjhj | hjhlkjhj | jhjkjh | jhjklk"],
    ["Don Omar - Danza Kuduro", "e u p u t [sp] [sp] [oa] [sp]\nq e i t e [ip] [ip] [uo] [uo]\nt o s o u [sf] [sf] [ad] [ad]\nr y o y r [ao] [ao] [ao] [sp]"],
    ["Dungeon Siege", "Y Y Yyyt Y Y Yyyt\ny y yYi o o o\no o oiiY i i ioOoi Y Y Yyyty o"],
    ["Edip Akbayram - Hasretinle Yandi Gonlum", "klll zx zlk\njkkk lz lkj\nhjjj jk hgg\nfggg h j g f"],
    ["Edvard Greig - Morning Mood", "spoiop spoiopop spsdpdspoi spoiop spoiopop spsdpdfSap fSapaS fSapaSaS fSfgSghgds spoiop spoiopop spsdpd[pg]"],
    ["Edvard Grieg - In the Hall of the Mountain King", "y u i o p i p | O u O | o u o\ny u i o p i p | O u O | o u o\ny u i o p i p d s p i p s\no p a s d a d | D a D | d a d\no p a s d a d | D a D d\nd f g h j g j | H f H | h f h\nd f g h j g j | H f H | h f h\nd f g h j g j z l j g j l\nh j k l z k z | Z k Z | z k z\nh j k l z k z | Z k Z z"],
    ["Eiffel 65 - I'm Blue", "PyoPsipPoPdDod\nsPyoPsipPoPdDod\nsPyoPsipPoPdDod\nsPyoPptioEioiop"],
    ["Eiffel 65 - I'm Blue (v. 2)", "[PJ][yd][oh][PJ][sl][ig][pj][PJ][oh][PJ][dz][DZ][oh][dz]\n[sl][PJ][yd][oh][PJ][sl][ig][pj][PJ][oh][PJ][dz][DZ][oh][dz]\n[sl][PJ][yd][oh][PJ][sl][ig][pj][PJ][oh][PJ][dz][DZ][oh][dz]\n[sl][PJ][yd][oh][PJ][pj][ts][ig][oh][EP][ig][oh][ig][oh][pj]"],
    ["Elfen Lied - Lilium", "ISOppISOpapI Ouyu IyTyuIOpapO\nISOppISOpapI Ouyu\nIyTyapOIiYiI\nPaSdpOdSfpSapOIi PaSdpOdSfpSapOIiI\nSOppISOpapI Ouyu IyTyapOIiY"],
    ["Endless Love - Autumn in My Heart", "lk [il] ps [gj] lk [tl] up [fh] gh [tj] ip [ik] [yl] [ix] [wz] yuios [ah] uo\n[1t] w [yi] w [tu] you [7p] wrw [wro] 4et [ei] iops [3a] wr [3r]\nas [4t] ete [4t] as [1t] wyw [wtu]\n[4o] [ei] [tu] iut [5y] rypo\nlk [il] ps [gj] lk [tl] up [fh] gh [tj] ip [ik] [yl] [ix] [wz] yuios [ah] uo\n[1t] w [yi] w [tu] you [7p] wrw [wro] 4et [ei] iops [3a] wr [3r]\nas [4t] ete [4t] as [1t] wyw [wtu]\n[4o] [ei] [tu] uiu [5o] rypo3wr\nooiuu6tuppoii4etiiopa [5s] ryap [5p] ryo ui\n[1o] w [yi] w [tu] io [4p] ete [4t] op [3a] wra [6s] etas [4p] [eo] [ti] uyui\n[1o] w [yi] w [tu] io [4p] eteop [3a] wra [6s] etps [4f] [ed] te [4t] as [5d] rysa\n[1s] w [yi] w [tu]\nlk [il] ps [gj] lk [tl] up [fh] gh [tj] ip [ik] [yl] [ix] [wz] yuios [ah]"],
    ["Enrique Iglesias - Heroo", "a a [oa] | a a [oa] p o [uo]\ns s [su] | s s [su] a p [py]\na a [oa] | a a [oa] p o p o\ns s [suo] | [ua] [yp] | o [oy]\na a [oa] o | d |\na a [oa] p o [ou] o | d |\ns s [su] a p [py] o | d |\ns s [suo] | [ua] [yp] | o [oy]\n[oad] d f ff | d [oaf] [oaf]\n[oad] d f ff | d [oaf]\n[oad] d f ff | d [oaf] [oaf]\n[oad] [oad] [pd] a [os] a [uo]"],
    ["Enya - Only Time", "ttt yrrtt yrrtt yrt"],
    ["Erol Evgin - Iste Oyle Birsey", "SSPPpp\nddSSPP\nffddSS\nSSPPop\nghjjJ jh gff\nfghhj hg fdd\ndfggh gf dSS\nff dd SS\nSSPPpp\nSSPPpp\nghjjJ jh gff\nfghhj hg fdd\ndfggh gf dSS\nff dd SS\nSSPPpp\nSSPPpp"],
    ["Evanescence - Bring me to life", "f k j k l x k\nk j k l x\nk [kh] j k [hl] k j k [hf] f G h j\n[kh] j h l k j k [hf] f G h j h G\na pa s a p a o u I opoI\na pa s a p a o oI y u\naaapp | aaapp | sapo | s a p a u\naaapp | aaapp | sapo | s a p a | s | o | p | I | u\nu I o p\ns ssapa opap op aa\ns ssapa opap op aa\naaapp | aaapp | sapo | s a p a u\naaapp | aaapp | sapo | s a p a | s | o | p | I | u\nu I o p\ns ssapa opa p op aa\ns ssapa opa p op aa\n[ow] [IQ] [y9] [u0]"],
    ["Evanescence - My Immortal", "Tupu Supu TuOuS Tupuaupu TuOuS"],
    ["Final Fantasy - Preludeo", "8 9 0 w t y u o s d f h l z x v m v x z l h f d s o u y t w 0 9\n6 7 8 0 e r t u p a s f j k l x b x l k j f s a p u t r e 0 8 7\n4 5 6 8 q w e t i o p s g h j l c l j h g s p o i t e w q 8 6 5\n[5] 8 9 ( [w] t y [y] [o] s d [d] [h] l z [z] [v] [z] z l [h] [d] d s [o] [y] y t [w] ( 9 8\n[6] 8 9 q [e] t y i [p] s d g [j] l z c [b] c z l [j] g d s [p] i y t [e] q 9 8\n5 6 [6] 9 w e [e] y o p [p] d h j [j] z v b [b] b v z [j] j h d [p] p o y [e] e w 9 [6] 6 5"],
    ["Final Fantasy IX - Song Of Memory", "q e t i [op] i [ut] e q E y i s i [oy] E\nq e t i [op] i [ut] e q E T i [syi] [PT]\no [pt] P [0o] [pteq] p s [outw]\nop P p P d p i [dyq] s [sp] P [PT] p [oyte]\np [PE] y i o [utw] t [pq] [oe] [pt] [se] [to] u o\nop P p P [su] [di] [su] [PY] p [py] o [ouw]\nq [sou] P P p [put] o [iut] u\n[iq] e [ot] p [ue] r t u\nu i u t y E e P p [py] o [oq] E [it] y [ut]io E y t u\n[iq] e [ot] p [se] r [pt] u\nE t E e E E e P p p o o y i s P P p p o p P [st]\ni o P o i y i [su] p P p u o [oq] E y [it] i [ow] [pt] P\n[pq] e t i [op] i [ut] e q E y i s i [oy] E\nq e t i [op] i [ut] e q E T i [syi] [PT] o [pt] P [0o] [pteq]"],
    ["Final Fantasy VII - Aerith's Themeo", "z L k j f f f G h G f d h k [yde] j z [upfl] j f [yde] j z L x z k L [sfj] [psf] I [pe] [dy] u I u y e [s6] [p0] [ue] r t r e y u [y6] 9 q u i [ue] y u [y5] 9 w E w 9 [ey9] Q e y I p d G j d G j z y u I Q r T y I o p * Q e T a S [d9] w r y a o p a p e p I T Q e T [SL] [ak] [pj] a p [y9] 9 w r [ak] [pj] [oh] [pj] [IG] [TS] * Q e [SL] [ak] [pj] k L [zd5] 9 w r o d h z [LQ] [ke] [j69] e [G G] 5 [wry] z L [kw] r [C C] w 5 w r y z L k [j5] [fe] [fu] f G [yoah] G f [dp] h j z [yoah] G d f [2d] 6 9 0 Q e u p z d G L d G k d G [uoadj] [pf] f G [oadh] [GL] [fx] [dz] [0ryo] [6wyua] S [2d] 6 9 Q e y"],
    ["Final Fantasy X - To Zanarkand", "[aux] f h k [xy] C [vt]\n[ypz] d G j [zt] x C\n[tk] o [sk] d [fk] h [fhk]\nj z G r I a S D G k\n[ex] u [pax] a [sx] d [xfp] z v\n[ql] o s d f h l h l z x v\n[fo0] r u a [fo] G [a8h] w G y u\n[fto] [so] [9d] e y [ufo] [dp] [a5] 9 w e [rho] [yjp]\n[fo0] r u a [fo] G [a8h] w G y\n[oh] [9j] e y [ufh] [jy] [5hk] 9 w e r y\n[fo0] r u a [fo] G [a8h] w G y u\n[fto] [so] [9d] e y [ufo] [dp] [a5] 9 w e [rho] [yjp]\nt a d f k k k y s f k j z [seh] u o h G d\n[1wu] 3 5 8 0 w t u o s f o s f h\n[6jp] 0 e r [tlx] u [4lx] 8 [qzdk] [sejl] [wahk]\n5 9 w e r [yhv] [3hv]\n7 0 Q w e r d k C C s e j [xj] [xj] Z\n7 6 [ay7] I a G a G [9af] w [rad] w S\n6 0 e [fjp] e [jp] $ * Q e T\n[ISH] f S [woa] G p [QTI] [up] [ar7]"],
    ["Final Fantasy X-2 Main Theme", "48we 59er 60rt 37Iw\nh [l4] 8 k [hw] e d [l5] 9 k [hr] t g [f6] 0 z [zr] t w 5 9 e r e\n[hw] [l4] 8 k [hw] e d [l5] 9 k [hr] t g [f6] 0 z [zr] t l [z5] 9 x [le] r k\nh [h4] s 8 w e h [h5] d 9 e r h [h6] f 0 r t h [h5] d 9 e r\nh [h4] [si] 8 w e h [ha5] [dy] 9 e r h [hs6] [fu] 0 r t h [ha5] d 9 e r\nh [l4] 8 k [hw] e d [l5] 9 k [hr] t g [f6] 0 z [zr] t [wl] [z5] 9 e r e\n[hw] [lg4] 8 k [hw] e d [l5] 9 k [hr] t g [fa6] [0s] z [zr] t l [z5] 9 x [le] r k\nh [h4] [si] 8 w e h [ha5] [dy] 9 e r h [hs6] [fu] 0 r t h [ha5] d 9 e r\nh [h4] [si] 8 w e h [ha5] [dy] 9 e r h [hs6] [fu] 0 r t h [ha5] d 9 e r y\n48we 59er 60rt 37Iw\n48we 59er 60rt u p a s [eu6]"],
    ["Final Fantasy XI - Sarutarubaru", "5 9 w r w 5 9 e t u t e\n5 9 w r w 5 9 e t i [to] [eo]\n[5d] w r [wo] [5o] [9d] e [tf] [ug] [tf] [se]\n[5a] 9 w [rs] [wa] [5o] [9p] e [to] [io] t [eo]\n[5d] 9 w r w [5o] [9d] e [tf] [ug] [tf] [se]\n[5a] 9 [wd] r [wd] [5h] [9j] [ek] [th] [ij] t [eo]\n[5d] w r [wo] [5o] [9d] e [tf] [ug] [tf] [se]\n[5a] 9 w [rs] [wa] [5o] [9p] e [to] [io] t [eo]\n[5d] 9 w r w [5o] [9d] e [tf] [ug] [tf] [se]\n[5a] 9 [wd] r [wd] [5h] [9j] [ek] [th] [ij] t [eh]\n[5h] 9 w [wr] [we] [5r] [9t] e u [ty] [eu]\n[5y] 9 w [wr] w 5 [9i] e t i t [et]\n[5dy] 9 w [ro] w [5o] [9id] e [tof] [upg] [tof] [eus]\n[5ya] 9 w [ros] [wa] [5o] [9ip] e [tuo] [yio] t [eyo]\n[5dy] 9 w [ro] [wa] [5o] [9id] e [tof] [upg] [tof] [eus]\n[5ya] 9 [wod] r [wod] [5ah] [9sj] [edk] [tah] [isj] t [eo]\n[5dy] 9 w [ro] [wo] [5o] [9id] e [tof] [upg] [tof] [eus]\n[5ya] 9 w [ros] [wa] [5o] [9ip] e [tuo] [yio] t [eyo]\n[5dy] 9 w [ro] [wa] [5o] [9id] e [tf] [usg] [tpf] [es]\n[5ad] 9 [wh] [ro] [wph] [5aj] [9sj] [edk] [tah] [isj] t [eah]\n[5ah] 9 w r [wp] [5o] [9s] e t u [ta] [es]\n[5d] 9 w [ro] w 5 [9f] e t [id] [to] [yp][5yr]"],
    ["Forget You", "s s s [duo] f s s s [dyi] p\ns s [sip] s s s [dip] p p [tou]\ns s s [duo] f s s s [dyi] p\ns s [sip] s s s [dip] p p [tou]\nf h j [zou] l d [fip] h f d [sip] s p s\n[ht] [fu] [dy] [st]\ns f h [jt] h j [DY] d s [sip] d D [dip] s p s\n[ht] [fu] [dy] [sip]\ns f h [jt] h j [DY] d s [sip] d D [dip] s p s\n[ht] [fu] [dy] [sip]\n[hut] [fot] [dyi] [stu]\n[hut] [fot] [dyi] [sip] [stu]"],
    ["Forrest Gump - Themeo", "w y y y y w y y y y w y y y y w y y y y\n[woa] [ys] d d w a y d w [ah] y d w a y w\n[ws] [ud] [of] f w [os] u f t o s o t [os] r [oa]\n[eos] [ud] [of] f e [os] u f 9 [dj] p G 9 d p y\n[wa] [os] d d w h o d w o a d o d I d\n[uf] G [ah] h u f a h u a f h u f u a\n[tos] d [of] f t s o f t u o s t [os] r [oa]\n[eos] [ud] [of] f e [os] u e [9p] y a [os] 9 p y\nw y y y y w y y y y w y y y y w y y o o p\n[woa] [ys] d d w a y d w [ah] y d w a y w\n[ws] [ud] [of] f w [os] u f t o s o t [os] r [oa]\n[eos] [ud] [of] f e [os] u f 9 [dj] p G 9 d p y\n[wa] [os] d d w h o d w o a d o d I d\n[uf] G [ah] h u f a h u a f h u f y o\n[tos] d [of] f t s o f t u o s t [os] r [oa]\n[eos] [ud] [of] f e [os] u e [9p] y a [os] 9 p y\nw y y y y w y y y y w d h j z j d h"],
    ["Franz Schubert - Ave Maria", "E eEy t E | t ytEeweE | y ytEew yuy T | et EetyYteE | ytt ewryiyrt weEewq\nqt ttrtytyE | Et ttrtYytE | Et ty yytyiY | w yt tEeETtEt | E eEy t E"],
    ["Franz Schubert - Ave Maria (v. 2)", "P pPd s P | s dsPpopP | d dsPpo dfd S | ps PpsdDspP | dss poadgdas opPpoi\nis ssasdsdP | Ps ssasDdsP | Ps sd ddsdgD | o ds sPpPSsPs | P pPd s P"],
    ["Franz Schubert - Ave Maria (v. 3)", "J jJz l J | l zlJjhjJ | z zlJjh zxz L | jl JjlzZljJ | zll jhkzczkl hjJjhg\ngl llklzlzJ | Jl llklZzlJ | Jl lz zzlzcZ | h zl lJjJLlJl | J jJz l J"],
    ["Franz Schubert - Ave Maria (v. 4)", "[EP] [ep] [EP] [yd] [ts] [EP] | [ts] [yd] [ts] [EP] [ep] [wo] [ep] [EP] |\n[yd] [yd] [ts] [EP] [ep] [wo] [yd] [uf] [yd] [TS] |\n[ep] [ts] [EP] [ep] [ts] [yd] [YD] [ts] [ep] [EP] |\n[yd] [ts] [ts] [ep] [wo] [ra] [yd] [ig] [yd] [ra] [ts] [wo] [ep] [EP] [ep] [wo] [qi]\n[qi] [ts] [ts] [ts] [ra] [ts] [yd] [ts] [yd] [EP] |\n[EP] [ts] [ts] [ts] [ra] [ts] [YD] [yd] [ts] [EP] |\n[EP] [ts] [ts] [yd] [yd] [yd] [ts] [yd] [ig] [YD] |\n[wo] [yd] [ts] [ts] [EP] [ep] [EP] [TS] [ts] [EP] [ts] |\n[EP] [ep] [EP] [yd] [ts] [EP]"],
    ["Frederic Chopin - Funeral Marcho", "[246y] [246y] [246y] [246y] [4i] [4u] [4u] [246y] [246y] T [246y]\n[4p] [4o] [4i] [4u] [4u] [4p] [4o] [4i] [4u] [4u] [246e]\n[246y] [246y] [246y] [246y] [4i] [4u] [4u] [246y] [246y] T [246y]"],
    ["Frederic Chopin - Funeral Marcho (v. 2)", "y yyy i uu yyTy\np oiuu poiu e\ny yyy i uu yyTy"],
    ["Fun - Carry On", "4 8 w q 4 8 w q 5 9 e w 5 9 e w [4it] 8 w q\n4 8 w q [5yo] 9 e w 5 9 e w [4y] [8u] [wi] q\n4 8 w q y [5ry] [9ru] [eri] w 5 9 e w [4y] [8u] [wi] q\n4 8 w q y [5ry] [9ru] [eri] p o i d [4pj] [8sl] [whv] [qgc]\n[4pj] 8 w q j [5pj] [PJ] [wdz] [cg] [hv] [BJ] z [4qt] t i\n[49t] E y y i y t e t E e [4eq]"],
    ["Game of Thrones", "o t Yio t Yiy\ni E yYi E Yyt\no t Yio t Yiy\ni E yYi E yYyt\nh s Dgh s Dgd\ng P dDg P dDds"],
    ["Game of Thrones (v. 2)", "[8wo] t | Yio t | Yio t | Yio t | Yio t Y i\n[8wo] t | uio t | uio t | uio t u i\n[8wto] t Yio t Y i [59y]\nE t y w | E t y w | E t y\nw E t [qi] E Y y i E [48y] y t\nW E t q | W E t q | W E t q W E\n[tos] w [YD] [ig] [oh] [wts] [YD] [ig] [59wyd] [wp] [EP]\n[ts] [yd] [wo] [EP] [ts] [yd] [wo]\n[EP] [ts] [yd] [wo] [Ep] [ts] [igq] [EP] [dyq] [YD] [yd] [ts]\nW E t q | W E t q | W E t q\nW E t q | W E t q | W E t q"],
    ["Gary Jules - Mad Worldo", "[es] f [ua] s [ep] a [uo] I 9 e u 9 y e s e f [ua] s [ep] a [us] d 9 e y o I u y e\n[es] f [ua] s [ep] a [uo] I 9 e u 9 y e s e f [ua] s [ep] a [us] d 9 e y o I e [tp] p\n[es] s [tp] p [tf] [sf] u f t s u [wad] d y d w a y [9pd] d y d 9 s [ya] p e [tp] p\n[es] s [tp] p [tf] [sf] u f t s u [wad] d y d w a y [9pd] d y d 9 s [ya] p e [tp] p\n[es] s [tp] p [tf] [sf] u f t s u [wad] d y d w a y [9pd] d y d 9 s [ya] p e [up] p\n[eps] s [sf] f G [ypd] e d G G [ypd] d [ep] u p [eps] s [sf] [sf] G d [ypd] [ed] G G [ypd] d e [up] p\n[eps] s [sf] f G [ypd] e d G G [ypd] d [ep] u p [eps] s [sf] [sf] G d [ypd] [ed] G G [ypd] d e [up] p\n[eps] s [sf] f G [ypd] e d G G [ypd] d [ep] u p [eps] s [sf] [sf] G d [ypd] [ed] G G [ypd] d e u o e s [oaf]\n[eyp] e a s f a s p o e u o e s [oaf]\n[eyp] e a s f a s p e [tp] p [es] s [tp] p [tf] [sf] u f t s u [wad] d y d w a y [9pd] d y d 9 s [ya] p e [tp] p\n[es] s [tp] p [tf] [sf] u f t s u [wad] d y d w a y [9pd] d y d 9 s [ya] p e [up] p\n[eps] s [sf] f G [ypd] e d G G [ypd] d [ep] u p\n[eps] s [sf] [sf] G [ypd] e G G [ypd] d e u o e s [oaf]\n[eyp] e a s f a s p o e u o e s [oaf]\n[eyp] e a s f a s p [es] f [ua] s [ep] a [uo] I 9 e u 9 y e s e f [ua] s [ep] a [us] d 9 e y o I u y e\n[es] f [ua] s [ep] a [uo] I 9 e u 9 y e s e f [ua] s [ep] a [us] d 9 e y o I"],
    ["Gladiator - Honor Him", "y o P | t E | e\nt y i | E e | w | y o P | t E | e\ne w q | w | w\ny o P | t E | e\nt y i | E e | w\ny o P | t E | e\ne w q | w | w"],
    ["Godfather", "osDdsDsdsOPo\nosDdsDsdsoIi\ni O a d\ni O a s\ntYPOoPOOoort\nssaP dsOo\noPoi | i O i o\nPosDdsDsdsOPo\nosDdsDsdsoIi\nssaP dsOo\ntYPOoPOOoort"],
    ["Godfather (v. 2)", "1 [t8] [ow] [t8] [iy] | 1 [t8] [ow] [t8] W\no s D [d1] [s8] D [s8] d s O P [o1] 8 w 8 W\no s D [d1] [s8] D [s8] d s o I [i4] 8 W 8 q\ni O a [d4] 8 q W t | i O a [s1] 5 8 ( w\nt Y P O [o9] w P O O o o r [t1] 5 8 ( w\ns s a P ^ 9 q E | d 9 q s O o ( w E\no P o [i4] * q W % | [i9] [OW] I [o5] 9 w 9 5\no s D [do1] [s8] D [s8] [di] s O P [o1] 8 w 8 W\no s D [do1] [s8] D [s8] [di] s o I [i4] 8 W 8 q\ni O a [d4] 8 q W t | i O a [s1] 5 8 ( w\nt Y P O [o9] P O O o o a [s1] 5 8 ( w\n[ho] [ls] [ZD] [zd] [ls] [ZD] [ls] [zd] [ls] [HO] [JP] [h1] [otw] [tq]\n[ho] [ls] [ZD] [zd] [ls] [ZD] [ls] [zd] [ls] 8 [ho] [GI] [g4] [i8] [tq]\n[gi] [HO] [ka] [z4] [d8] [tq]\n[gi] [HO] [ka] [l1] [sw8] t\n[st] [DY] [JP] [HO] [ho] [w9]\n[JP] [HO] [HO] [ho] [ho] [ar] [s1] [tw8] t\ns s a P ^ 9 q E y i | d 9 q s y O o ( w E\no P o i % * q W T % | i [OW] I [o5] 9 w 9 5\no s D [do1] s [D(] s [di] [st] O P [o1] [tw] [tq]\no [s8] D [do1] s D s [di] s 8 o I [i4] W 4\ni O a [d4] W 4 | i O a [s1] [w8] 1\nt [Y(] P O o P O O o [orq] P [s8]"],
    ["Gravity Falls - Themeo", "gdpd gdpd\nspsg spsf\nSpSf SpSf\nSpSf SpSf\nSpS d | fg | j h j | s |\nd | fg | f | h | j | h | g\ng g g jjhg\nj j j h j h g\ng g g j j h g\nj j j | LLL\ng g g j j h g\nJJJ j l j L\ndgjl Sgjl d | y"],
    ["Greensleeves", "f h j k l k j G d f G h f f D f G D a\nf h j k l k j G d f G h G f D S D f f f\n[kz] [kz] l k j G d f G [hf] f f D f [DG] D a\n[kz] [kz] l k j G d f G [fh] G f D S D f [hx]"],
    ["Hababam Sinifi", "x k lzlkj HgH j k\nx k lzlkj HgH d f\nggfd g f\njjHg j H\nkkjH k j\njklZx\njkjh f\nf H k xx\nf H k zz\nz k H g d f\nx k lzlkj HgH j k\nx k lzlkj HgH d f"],
    ["Hallelujaho", "8 w t u t 7 6 0 e t e 6\n8 w t u t 7 6 0 e t e [6u]\n[8o] w [to] [uo] t [7o] [6p] [0p] [ep] t e [6u]\n[8o] w to [uo] t [7o] [6p] [0p] [ep] t e [5o] [4p] 8 [qp] e [qp] [4p] [5p] 9 [wo] [ro] w [5i]\n[8o] w t [uo] t 8 5 9 w r w [9u]\n[8uo] w [tuo] [uo] t [8uo] [4ip] 8 [qip] [5oa]\n9 [wo] [6us] 0 [eus] [tus] e [6us] [4us] 8 [qus] [ed] q [4s] [5pd]\n9 [wd] r w [5d]f r u [af] d [6d] 0 e [tps] e 0 6 0 e [tu] e\n[6o] [4p] 8 q [ep] q 8 4 8 q [ep] q [4o] [6u] 0 e tu e\n0 6 0 e tu e [6o] [4p] 8 q [ep] q 8 4 8 q [ep] q [4o] [1u] 5 8 0 [8i] [5u] [5y]\n9 w r [wt] [5r] [6e] 0 e [ru] [tp] [us] [pf] j k l x jlxb"],
    ["Happy Birthday", "y y u y o I\ny y u y p o\ny y d a o I u\ns s a o p o\nd d f d h G\nd d f d j h\nd d z k h G f\nl l k h j h"],
    ["Harry Potter - Hedwig's Themeo", "a [fu] h G [fu] k [ju] G\n[fu] h G [PD] g [ua] | o a\nr [fu] h G [fu] k [d h z] L [s g i] H [s f l] k J [TIP] h [fu] | o a\n[rh] [uk] [oh] [ak] [rh] [ul] [ok] J\nG [uh] J P [oa] [uk] | o a\n[rh] [uk] [oh] [ak] [oh] d [z o y] L s [t i l] H [s f l] k J [TIP]\nk [uoaf]"],
    ["Harry Potter - Lilys Themeo", "o [6ep] f d s a p o [6ep] f [efh] ef f h j h j k [efl] k j j f [efh] [wdj] h [ef] [ef] h j [edh] j k [efhl] k j j [ef] h j h [efj]"],
    ["Harry Potter - Theme", "afhGfkjG fhGdga afhGfkzLl jlkJahf\nhk hk hlkJ hG lk ah l\nhk hk HzLl hLlkahf\nr u o I u a p I u o I Y i r\nr u o I u a d S s O s a P p o u"],
    ["Harvest Moon Back to Nature - Opening 2", "s d f f g h h j h g j h s d f f g h s g f d s d f f g h h j h g j h s g g f d s a s"],
    ["Hesperidia - Sphere Of Stars", "y p G p h p G p | t p f p G p f p\nr I d I f I d I | w y a y S y a y\nk j j G k j j G | k j L j k j L j\nz k k G z L k G | z L L k j G d f\nG h j G k j j G | k j L j z j x j\nv C C z z L k g | k z L k j G d f\nG | f | d | p | d | S | a | S a p\nS f\nk j j G k j j G | k j L j k j L j\nz k k G z L k G | z L L k j G d f\nG h j G k j j G | k j L j z j x j\nv C C z z L k g | k z L k j G d f"],
    ["Hunger Games - Rue's Whistleo", "v B b [zo]\nd h j J j h d D h J Z c J h Z\nc P d g J l J g d s D Z l z h D l [zo]\nJ [zh] v B d P d [sb] D h l b f h v d G j z C\nw y [oh] y J y P j [yd] I p d f d G d\nT o P D g D P D\nG y i P s P d P t T D o d o T s [dw]\ny o s P o y ) [ts] T o P I y e p\n[wo] ) y o p P d h s D [vh] l v h D b\n[ov] d [zhv] d j J j h s D\n[vh] l v h D b [ov]"],
    ["Inception - Timeo", "[et] | [o0] | [wr] | 9\n[et] | [8a] | [wr] | 9\n[etpa] | [0uoh] | [wroa] | [9y]\n[etpa] | [8tak] | [wroa] | [9y]\n[6ts] t r e [3oh] 0 Q 0\n[5ra] r e w 2 9 0 9\n[6ts] t r e [1ak] 8 e w\n[5ra] r e w 2 9 u [yd]\n[6ts]e [6s]e [6s]e [6p]e [0oah] u I u\n[5ra]w [5a]w [5p]w [5o]w [9p] y u y\n[6ts]e [6s]e [6a]e [6p]e [8afk] t p o\n[5ra]w [5a]w [5p]w [5p]w [5o]w [yp] [ypd] [9uaf] [2yd]"],
    ["Inception - Timeo (v. 2)", "[6et] 6 6 6 [3ro] 3 3 3 [5wr] 5 5 5 [2e] 2 2 2\n[6et] 6 6 6 [10wr] 1 1 1 [59wr] 5 5 5 [29] 2 2 2\n[680t] 6 6 6 [30wro] 3 3 3 [57wr] 5 5 5 [29] 2 2 2\n[et680] 6 6 6 [180wr] 1 1 1 [59wr] 5 5 5 [29] 2 2 2\n[68e] 7 6 [350w] Q w [57wr] 6 7 [269] 0 Q\n[6etps] r e [30wuk] I o [5wrya] e r [29p] u I\n[6ets] r e 0 [30wro] [5wra] e r y [29pd]\n[6etps] r e 0 [18etuoa] e w 0 [5wra] e r y [29d]\n[60esl] [ra] [ep] [0u] [30wh] [QI] [wo] [ep] [5wak] [ep] [9y] [0u] [29] [0u] [QI] [ep]\n[60esl] [ra] [ep] [0uoa] [1afhk] [ep] [wo] [ep] [5wrya] [ep] [wo] [odkz] [29z] [0u] [9y] [0u]\n[6esfl] [ak] [uf] [37ofh] [IG] [ra] [5wak] [pj] [yd] [269] [uf] [ep]\n[60sfl] [sl] [uf] [15ahk] [oh] [ts] [59rya] [pj] [yd]\n[69] [uf] [ep] [6ps] [jl] [30h] [af] [5oa] [dk] [2y] [fhk]\n[psjl] [tafk] [oafk] [ypd] [ps] [uh] [oa] [y] [ps] [tk] [oa] [ydf] GGGGGGGG"],
    ["Inspector Gadget", "pasd fs Da ds\npasd f j H\npasd fs Da ds\npasd f\nH j"],
    ["Iron Man", "r y | y u u | o I o I o I y y u u\nr y | y u u | o I o I o I y y u u\nr y | y u u | o I o I o I y y u u"],
    ["Istiklal Marsi", "u I o Y I u\nu p a s O a p\na P a I I p o Y u I o p a s d f d\ny a p o\nrEr I Y a p o I u\nf d s a p o I u a Y u"],
    ["James Blunt - You Are Beautiful", "w Y y Y\nw Y y Y i\nY y Y w W\nw Y y Y\no P o P Y\nw Y y Y i\no P o P Y Y\no P o PPP\no P o P YY\no P o PP YY\nPP P P P o P Y\nE E P o P o P Y\nP P Y P P Y Y P P Y P\nD J h h\nD D J h h\nD D J h h J\nD D J h\nD D D J h\nD D s d D h g d s\ns d D h g d D"],
    ["James Bond - Themeo", "0QQQQ 0000 0wwww QQQQ\n0QQQQ 0000 0wwww QQQQ\nQQ0Yy rer"],
    ["James Bond - Themeo (v. 2)", "uIIII uuuu uoooo IIII\nuIIII uuuu uoooo IIII\nIiuDd apa"],
    ["James Bond - Themeo  (v. 3)", "fGGGG ffff fhhhh GGGG\nfGGGG ffff fhhhh GGGG\nGgfZz kjk"],
    ["James Horner - Braveheart Themeo", "6 0 e 0 r 0 t 0 6 0 e 0 r 0 t 0 6 0 e 0 [ru] 0 [to] 0\n[4p] 8 q 8 [wp] 8 [es] 8 [5a] 9 w [9s] a [ep] 9 [ro] 9\n[6o] 0 e 0 r 0 [tp] 0 [6u] 0 e 0 [ru] 0 [to] 0\n[4p] 8 q 8 [wp] 8 [es] 8 [5a] 9 [ws] [9a] [ep] 9 [ro] 9\n[6o] e 0 [rp] 0 t 0 6 0 e 0 [rf] [0h] [ij] e t q [ej] q [tl] e\n[ok] r y [wl] k [rj] w [yh] r [eh] u p u a u [sj] [uh]\n[ef] u p u [af] [0h] [ij] e t q [ej] q [tl] e\n[ok] r [yl] [wk] [rj] w [yh] r [eh] u p u [aj] u s u\ne u p u [af] u [sh] [ij] j h [yd] o s d [ug] s f [td] p s [ep] i\ne t y u y u y t r e"],
    ["Jason Bourne - Theme", "h f | hHh f | hD gS s | h f | hHh f\n[ho] [fu] | [ho] [HO] [ho] [fu] | [ho] [DY] [gi] [ST] [st]\n[ho] [fu] | [ho] [HO] [ho] [fu]\nss hh HH hh gg hh DD SSs\nss hh HH hh JJ hh gg hh DD SSs\n99 ee EE ee ww ee qq ( (\n99 ee EE ee TT ee ww ee qq ( (\nee Tt EeEt | ee Tt EeETEe | e eTt EeETEe\nyy pp PP pp oo pp ii YY\nyy pp PP pp SS pp oo pp ii YY\npp Ss PpPs | pp Ss PpPSPp\npp s pp S pp D ff J JJj JJj\n99 ee EE ee ww ee qq ( (\n99 ee EE ee TT ee ww ee qq ( (\nyy pp PP pp oo pp ii YY\nyy pp PP pp SS pp oo pp ii YY\ndd jj JJ jj hh jj gg DD\ndd jj JJ jj LL jj hh jj gg DD\ndd [gi] dd [GI] dd [HO] dd [dz]\nh f H J H h f | h D g S s\nss hh HH hh gg hh DD SSs\nss hh HH hh JJ hh gg hh DD SSs\nh f D S s"],
    ["Jingle Bells", "w [8w] u [5y] t [8w] 0 5 w [8w] u [5y] t [4e] q 8 e\n[9e] i [6u] y [5r] w 9 o [5p] o [9i] y [8u] 0 5 0\n[8w] u [5y] t [8w] 0 5 w [8w] u [5y] t [4e] q 8 e\n[9e] i [6u] y [5o] o [9o] o [5p] o [6i] [7y] [8t] [5o]\n[wu] [wu] [wu] [wu] [wu] [wu] [wu] [wo] [0t] [0y] [wu] 0 5 0\n[ei] [ei] [ti] [ti] [wi] [wu] [wu] [wu] [wu] [eu] [ey] [ey] [eu] [9y] [wo]\n[wu] [wu] [wu] [wu] [wu] [wu] [wu] [wo] [0t] [0y] [wu] 0 5 0\n[ei] [ei] [ti] [ti] [wi] [wu] [wu] [wu] [wu] [5p] o [6i] [7y] [8t] [tuos]"],
    ["Johann Pachelbel Canon - Musicbox Version", "[vhs] [xo]c[vf] xc[va]bvc[xd]cxz[lp] lz[xs] lz[xko]cxz[la]zlk[ji]\njk[lp] z [lu]zlklz[xo]c[xi] lz [xp] lz[xo]cxz[la]zlk[ls]fhl"],
    ["John Lennon - Imagine", "[10w] 8 [w0] 8 [w0] 8 [3wr] 8 [4qe] 8 [qe] 8 [qe] 8 [4qe] P r\n[10w] 8 [w0] 8 [w0] 8 [3wr] 8 [4qe] 8 [qe] 8 [qe] 8 [qe] 8\n[te4] q [te] [4q] [te3] q [te] [q3] [teq2] 9 [teq] [29] [teq1] 8\n[teq] [18] [ry5] w [ry] w [ry5] w [tu] w [yiw]\n[10w] 8 [w0] 8 [w0] 8 [3wr] 8 [4qe] 8 [qe] 8 [qe] 8 [4qe] P r\n[10w] 8 [w0] 8 [w0] 8 [3wr] 8 [4qe] 8 [qe] 8 [qe] 8 [qe] 8\n[te4] q [te] [4q] [te3] q [te] [q3] [teq2] 9 [teq] [29] [teq1] 8\n[teq] [18] [ry5] w [ry] w [ry5] w [tu] w [yiw]\n[te4] q [te] q [rw5] 9 [rw] [95] [tw1] 0 [rw] 0 [PO3] 0 [yw] 0\n[te4] q [te] q [rw5] 9 [rw] 9 4 [tw01] 8 2 8 1 8 2 8\n[10w] 8 [w0] 8 [w0] 8 [3wr] 8 [4qe] 8 [qe] 8 [qe] 8 [4qe] P r\n[10w] 8 [w0] 8 [w0] 8 [3wr] 8 [4qe] 8 [qe] 8 [qe] 8 [qe] 8\n[te4] q [te] [4q] [te3] q [te] [q3] [teq2] 9 [teq] [29] [teq1] 8\n[teq] [18] [ry5] w [ry] w [ry5] w [tu] w [yiw]\n[te4] q [te] q [rw5] 9 [rw] [95] [tw1] 0 [rw] 0 [PO3] 0 [yw] 0\n[te4] q [te] q [rw5] 9 [rw] 9 4 [tw01]"],
    ["Jurassic Park - Theme", "l lkl lkl zz cc xlz kh xlz vlc xx zz\nlkl lkl lkl zz cc xlz kh xlz vlc xx zz\nLkL j zz xx k\nLkL j x c v z\nxzx l vv x nk\nmnm l cc x z\nlkl lkl lkl zz cc xlz kh xlz vlc xx zz\nlkl hg lkl hg lkkl h s J\nlkl hg lkl hg lkkl h g l k l"],
    ["Karadayi", "j k l x l\nx z v\nk j"],
    ["Katy Perry - Firework", "tYYtt WEEWW WEEWW WWWEtt |\ntYYtt WEEWW WEEWW WWWEtt V\nYOOYY YYYiOO YOOYYYiYYtYY |\ntYYtt WEEWW WEEWW V WWEEWW |\nWEEWEt ET tY TYi OO YP OOPs O |\noitYYtt WEEWW | WEEWW WWWEtt |\ntYYtt WEEWW WEEWW WWWEtt | YOOYY |\nYYYiOO YOOYYYiYYtYY | tYYtt WEEWW WEEWW WWEEWW |\nWEEWEt ET tY TYi OO YP OOPs O oi | YDSsPPOs DSsPPOs |\nDSsP PPP YYDSsP PPP | DSsPPOs DSsPPOs | DSsP PPP YYYDSsPPPPO"],
    ["Katy Perry - The One That Got Awayo", "kHfH kHfH kHDH kHDH\nLHfH LHfH Ljfj Ljfj\nCCCC x xxCC | k x CCCCx xx CCC | k x CCCCx xx C V C x Z L\nkk CCCCk xxCCCC | xxCCC x V C x Z L\njjjH | jjjHGG | jjjjHHGfGHGfDS\nSSS aa [fk] [fk] [fk] HGG\njjjHHGfGHGfDS aa SS Saa\na GGGG f a fGG | a GGGG f a fGG\nfGGG fGHGfDS aSa fGGGf ff fGGG\nf GGGGf aHGfDS\na jjjHH | jjjHGG | jjjHH GfGHGfDS SS Saa\n[fk] [fk] [fk] HGG\naSSSaa GfGfG GfG [SH] f S\na HG aSSSaa GfGfG GfG [SH] f S\na [ak] [pj] [OH] [ISG] [OH]\na jjjHH | jjjHGG jjjHHGfGHGfDS HH GG ff[af]"],
    ["Katy Perry - The One That Got Awayo (v. 2)", "kHfH kHfH kHDH kHDH\nLHfH LHfH Ljfj Ljfj\nGGGG f ffGG # a f GGGGf ff GGG # a f GGGGf ff G H G f D S\naa GGGGa ffGGGG # ffGGG f H G f D S\npppO #pppOII # ppppOOIuIOIuYT\nTTT rr [ua] [ua] [ua] OII\npppOOIuIOIuYT rr TT Trr\nr IIII u r uII # r IIII u r uII\nuIII uIOIuYT rTr uIIIu uu uIII\nu IIIIu rOIuYT\nr pppOO # pppOII # pppOO IuIOIuYT TT Trr\n[ua] [ua] [ua] OII\n[ua]O [ua]O [ua]O [ua] O\n[ua]O [ua]O [ua]O [ua] O\nrTTTrr IuIuI IuI [TO] u T\nr OI rTTTrr IuIuI IuI [TO] u T\nr [ra] [ep] [WO] [QTI] [WO]\nr pppOO # pppOII pppOOIuIOIuYT OO II uu[ru]"],
    ["Kenshin's Song", "[psf] a s [ado] s a [spi] u [psf] a s [ado] s d [sfip] s [psf] a s [ado] s d\n[sfip] s f [ado] [sip] d s [ado] p o [sfp] u e [pe] t [au] [ts] [wd] r [sy] [ar] [qp] e\n[tu] e q [pe] t [au] [ts] [wd] r [sy] d [qfp] e [ts] e q [pe] t [au] [ts] [wd] r [sy] d [qfp]\ne [st] [fe] [wd] r y r [qst] d s [awy] p o [eup] [u0] [e6]"],
    ["Krem - Jenerik", "uuu usau | iii ioiuy\ntetyuyt | rrrr rtrer\nuuu usau | iii ioiuy\ntetyuyt | rrrr rtrer\no i ioiyuy | tet tet\nrrrr rtrer klkjk\nlkl f | lkl g\nklkjh ghgdf\njklf llkjh mmnbv\ndfg jjH HjkjHj\ndfg jjH HjkjHj\ndfg jjH HjkjHj"],
    ["Lady Gaga - Telephone", "s P O i Y i g D d P i o O\ngg DD HH hh h DDD DD DD DDD gg sss\ngg DD HH hh h DDD DD DD DDD gg sss\ngg ss"],
    ["Legs of Love", "T O I p O I O p T y\nr T y O I i I O r T\nT O I p O I O p S a\na p O a p p O I p O i y T I\nT O I p O I O p T y\nr T y O I i I O r T\nT O I p O I O p S a\na p O a p p O I p O I y u I\nS a p d d d I O\na p O S S S f p\np O I a a a d S a p a S\nS a p d d d I O\na p O S S S f p\np O I a a a p O T p O I"],
    ["Les Miserables - Castle on a Cloud", "[6p] a s [3a] [4p] [24p] [3O]\n[6p] p a s [5a] p o i [1u] [2y] u i\n[1u] p [3a] s [4p] [2y] u i [3u] y t r [6e]"],
    ["Linkin Park - In The Endo", "[9z] b b c [x8] x x x c\n[9z] b b c [x8] x x x c\n[9z] b b c [x8] x x c [8x]\n[9d] j j g [8f] f f f g\n[9d] j j g [8f] f f f g\n[9d] j j g [8f] f f f g\n[9d] j j g [8f]\n[9y] p p i [u8] u u u i\n[9y] p p i [u8] u﻿ u u i\n[9y] p p i [u8]"],
    ["Lion King - Can You feel the Love Tonighto", "[8u] w t w [5y] w o w [4i] q e i [8i] u t w [4e] q e t i\n[8t] w t q [29] 2 [30] 3 [4q] 4 [5w] 5 [4e] r [qt] [4y] t\n[80] w 5 8 0 [4e] r [qt]\n[8y] t 8 w 0 8 [4e] r [qt] [4t] [8y] [0t] w\n[8u] [9i] [eu] [9y] [eu] [5y] 9 w 9 [4e] r [qt] [4y] t\n[80] w 5 8 0 [4e] r [qt]\n[8y] t 8 w 0 8 [4e] r [qt] [4u] o 8 u 0 y y\n[8t] [9i] [eu] [9y] [eu] [5y] 9 w 9\n[8o] 0 w [8u] [5y] 7 [9o] [7u] 6 [0r] [6t] [4e] 8 q 8 4\n[80] w [9t] [8i] 9 e 9 [eu] [5y] 7 9 7 5 7 9 5 4 e r\n[8t] q 8 0 8 [0w] w [5o] 7 [6u] 0 [4t] t\n[8e] q 8 [9i] e [8u] 0 [9y] e [9u] [5y] 7 9 7 9 7 5 [7w]\n[8o] 0 w [8u] [5y] 7 [9o] [7u] 6 [0r] [6t] [4e] 8 q 8 4\n[80] w [9t] [8i] 9 e 9 [eu] [5y] 7 9 7 5 7 9 5 4 e r\n[8t] q 8 0 8 [0w] w [5o] 7 [6u] 0 [4t] t\n[8e] q 8 [9i] e [8u] 0 [9y] e [9u] [5y] 7 9 7 9 7 5 [7w]\n[8u] t [8w] t [7o] y [7w] y [8i] [qt]\n[8e] [qt] [5u] t [5w] t [4i] t [4w] t [3u] t [3w] 0 [7o] q\n[8u] t [9i] t [3u] w [4e] r [qt] [4y] t [80] w 5 8 0 [4e] r [qt]\n[8y] t 8 w 0 8 [4e] r [qt] [4t]\n[8y] [0t] w [8u] [9i] [eu] [9y] [eu] [5y] 9 w 9 [4e] r [qt] [4y] t\n[80] w 5 8 0 [4e] r [qt]\n[8y] t 8 w 0 8 [4e] r [qt] [4u] o 8 u 0 y y\n[8t] [9i] [eu] [9y] [eu] [5y] 9 w 9 [8o] 0 w\n[8u] [5y] 7 [9o] [7u] 6 [0r] [6t] [4e] 8 q 8 4\n[80] w [9t] [8i] 9 e 9 [eu] [5y] 7 9 7 5 7 9 5 4 e r\n[8t] q 8 0 8 [0w] w [5o] 7 [6u] 0 [4t] t\n[8e] q 8 [9i] e [8u] 0 [9y] e [9u] [5y] 7 9 w 9 7 5 6 4 e r\n[8t] q 8 0 8 [0w] w [5o] 7 [6u] 0 [4t] t\n[8e] q 8 [9i] e [8u] 0 [9y] e [9u] [5y] 7 9 w 9 [5wyo]"],
    ["Lion King - Circle of Lifeo", "w u t u t t 0 t 0 q q 8 8 8 7 5 7 w 9 9 0\nu t u t t 0 t 0 q q 8 8 8 7 5\n0 q w 0 q w w e w q q\n9 0 q q q q e w 0\n5 8 9 0 9 8\nw w t t t t e r t t y y e t y y u i u t y t r\n0 q w w w w w e w q q\n9 0 q q q q e w 0\n5 8 9 0 9 8\nw w t t t t e r t t y y e t y y u i u t y\nw w o u y u u\ni u i u u u i o i y y\ny i i o u y t t y t t w w i u y y y t r r w w o u y u y t t u u u i o o i i i Y y t t\nw w o u y t e w w w i u y t t i u i u t t\nw w w u u t t t i i i o i u i u y y w t y u t w w\n0 w t t r t t t t y u i u y t y u y\nt y u u t t t i i i o i u i u y y w t y u t w w\n0 w t t r t t t t y u i u y t t o\nw w o u y u u i u i u u u i o i y y y\ni i o u y t t y t y t w w i u y y y t r r w w o u y u y t t u u u i o o i i i Y y t t\nw w o u y t e w w w i u y t t t\ni u i u t t\nw 0 5 1 8 18"],
    ["Lion King - This Lando", "w t r e w e w e t t r e w e w e t t r t o o i u y i u t u y\nw t r e w e w e t t r e w e w e t t r t o o i u y i u t u y\ny u y e r e r y y u y e r\ne r w Q w Q 0\nw t e r e w r e\n9 w e r w Q w Q 0 y t r e w w r\nr u I o I p o u u o a f f d s a u I o I p o I u r u o I u Y Y u\nu y t r r e r t r e e 9 w e w 9 0 9 0 w w e w 9 0 9 0 w w e w y y t r e t r w r e w"],
    ["London Bridge is Falling Downo", "hjhgfgh d h f s\nhjhgfgh d f g f g h\nhjhgfgh d h f s\nhjhgfgh d f g f g h"],
    ["Lord Of The Rings - Many Meetingso", "[pdj] e T u i u T e | [60] e T u i [up] i [uf]\n[psg] q e t T t e q | [48] q e t T t e q\n[pfj] e T u i u T e | [60] e T u i [up] i [uf]\n[ipsf] q e t T t e q | [ips] q e t T t T t\n[pd] y I p P p I y | [269] y I p P p I y\n[oaf] y o a s a o y | [osf] t u o O o u\nt y [8wtu] o [0wru] y [qet] [0w]\nu o [4qtip] s [5wyo] o [30wtu] [29wry]\n[10wt] y u o u yuy[t0]\nu [ow] [tp] [sq] [ae] [eo] [1wtu] | i u [5wry]\nt y [0u] [to] [u8] yty[t0]\nu o [4p] [to] [uw] [5etu] i u [5ru] y | [6et] y u |\ne t e | e t e 6 | e t e | e t e [6u] | [ep] a [4ips] |\n8 e 8 | [8a] e [8p] [5ru] | [iq] u y\nt y [6etu] t y [eu] [tu] p a [4ips]\na p [5rto] [ry] [wt] y u o [wu] yuy[t8]\nu o [4tp] [sq] [wa] [oe] [1wtu] | i u [5wry]\nt y [18wto] t u o O o u t\n[18] t u i o O [ot] u o\n[30ru] u O a s a O u\n[30] u O a s a s a\n[9wyo] a d D d [9wo] | o a d D\nt y [8wtu] o [0wru] y [qet] | [0w]\nu o [etp] s [wrya] o [0wtu] [9wry]\nt y [8wtu] o [0wry] y [qet] | [0w] t\nu o [qetp] [0eto] [wu] [59wry] | [150wt]"],
    ["Lord Of The Rings - Misty Mountains Coldo", "y i [ow5] P [4qip] | s d s P p [5wo]\ny i o [4qtip] | P [18] s P p [5wo]\no y i o [4wyo] [5wyo]\nP [s4qi] P p i [5wo]\ny i o [4qyi] [4qyi] P [18] s P p o [4tip]\n8 e 8 4 [8p] P [8p] [5o]\n9 E 9 5 [9p] [EP] [9o] [2yip]\n6 Q 6 2 [6y] [iq] [6o] [4tip] [8tip]\ne 8 4 8 P [8i] [5wo]\n[yd] [ig] [5oh] 9 E 9 5 9\n[EPJ] [sl] [dz] [sl] [PJ] [pj] [5oh] 9 E 9 5 9 E 9\n[yd] [ig] [oh] [4PJ] 8 E 8 4 8\n[PJ] [sl] [PJ] [9oh] E 9 5 9 E 9 5\n[yd] [ig] [oh] [odh] [odh]\n[EPJ] [sl] [PJ] [pj] [ig] [5oh] 9 E 9 5 9 E 9\n[yd] [ig] [oh] [pj] [pj]\n[PJ] [sl] [PJ] [pj] [oh] [8pj] E 8 4 8 E 8 4 8\n[epj] [PJ] [pj] [9dz] E 9 5 9 E 9 5 9\n[ydq] [ig] [pj] [epj] 8 4 8 E 8 4 8\nP i [5wo] P d h"],
    ["Lord Of The Rings - Misty Mountains Coldo (v. 2)", "uop sdfdsap\nuopa sdsapa\nsdsf sdsa\nuops saop\nfhj lzxzlkj\nfhjk lzlkjk\nlzlx lzlk\nfhjl lkhj"],
    ["Lord Of The Rings - Rohan Theme", "0 e e e t [9wr] [0wt] [qey] e Q e w e e\n[0e] e e e t [9wr] [wtu] [ey] [yp] e y u y y [0e] u\n[up] [up] [up] [yoa] [uos] [uos] [ipd] [yp] [ua] o I o [uo] [tu]\np p p s [yoa] [uos] [ipd] [yp] [ua] o I o [uo] [tu]\n[0e] e e e t [9wr] [0wt] [qey] e Q e w e e\n[0e] e e e t [9wr] [wtu] [ey] [yp]\np p p s [yoa] [uos] [ipd] [yp] [ua] o I o [wo] [tu] [et] [qe]\np p p I [yoa] [uos] [ipd] [yp] [ua] o I o [uo] [tu] [et] [qe] [0e] [60p]"],
    ["Lord Of The Rings - Theme", "t y [80tu] o [70ru]\ny [68tu] [58] t u o\n[qeip] s [wroa] o [80tu] i u [79ry]\nt y [80tu] o [70ru]\ny [68tu] [58] t u o\n[qeip] [qeip] p o u\n[70ru]\n[680] e t u 8 6\n[579] w r y 7 5\n[80tu]"],
    ["Lost - Win One for Reaper", "[hwtu] [hwru] [wtu] [wru] [hwtu] f h f h f [hwru] k h\n[hwtu] [hwru] [wtu] [wru] [hwtu] f h f h f [hwru] k h\n[hqet] g h g h g [hwtu] g f g h\n[hqet] g h g h g [fwtu] [dwry] [s1wt]"],
    ["Mafia (The Game)", "[29dy] [gi] [30fu] s [4qdi] [p] [15uo] s\n[29dy] [gq] [30ft] [hw] [4eji] [q] [3wu] 0\n[29jp] [qls] [30ho] 8 [49oh] [6u] [15y] t 8 f g\n[29yf] [qs] [30d] w [4eyd] q t [3we] [0w]\n[29pj] [qsl] [30oh] 8 [49oh] [6u] [15y] t 8 f g\n[29yf] [qs] [30d] w [4eyd] q t [3we] [0w]\n[59hz] d [5s] [5rdz] [5f] j [8toh] 8 [8ufl] [8k] j k [6ufj] i 6 [6ef] [6g] [uf] s [49pd] 4 [49pf] [4g] f s\n[59hz] d [5s] [5rdz] [5f] j [6tjx] c l [5j] [5tj] 5 [$tDz] Z k [$D] [$tD] [$h] g d [$rak] k d [$a] [$ra] $ [8s] P O o [$s] P [Ei] Y [4wP] O [Wo] O [!Ti]"],
    ["Mandy Moore - Only Hopeo", "k k [ke]jH HGf\nSDfDS s S\nSDfDS H f [pf]\nSDfDS a G ff DSS a p [pu]\nSDfDS a G f [fp]\nSDfDS a G ff DSS a p [pu]\nSS [SO] | [SH] s s | [IP]\nSS [SO] | [SH] s s | [GJ]\nSS sPsS | [GJ]\nSS sPsS"],
    ["Maroon 5 - Moves Like Jagger", "r rTyuI | 77 | QQIuu\nr rTyuI | 77 | rrIuu\ny | Iuyy | [0r][0r] | Iuyy | [0r][0r] | Iuyy 0[0r]\nr rTyuI | 77 | rrIuu\nr rTyuI | 77 | rrIuu\ny | Iuyy | [7r][7r] | Iuyy | [7r][7r] | Iuyy | [7r]7\n[7r] e rr |\na p I | yyu ry | 77 | a p Iu | yyu ry | yyu re |\nyyyu re | yyy | r y u a p Iu | yyu re"],
    ["Mass Effect 3 - An End Once and For Allo", "[3r]70 | [1t]58 | [2r]69\n[3r]70 w e\n[3r]70Q [5y]9we [6t]0er [7y]QrT\n[3r]70Qert\n1580ytr 269Qrer 370Qwer\n370Q\nuau [y5]9we\nuau [t6]0er\nuau [y7]QrTy\n[370uw] | [158wu]\n[30wu] u u u u u [158wtu] u u u u\n[370wru] u u u u [59wryu] u u u u\n[60etu] u u u u [7ryu]\n[3a]70w[rp]aws\n1580[wd]s0a 269Q[ea]pQa 370w[ro]pwa\n370wr\nuau [5y]9we\nuau [t6]0er\nuau [y7] Q r T y [370af]"],
    ["Mass Effect 3 - I Was Lost Without Youo", "[y9] [qe] y [q9] [y9] [qe] y [q9] [y9] [qe] uioiuytr yuy yuyuiopypytety\n[y9] [qe] y [q9] [y9] [qe] uyuiioiuy\n[y9] [qe] [y9] [qe] w [qe] ghghfsad ghgh fdfsapa oiui yiui\nyt E te rery iuiop u apa oiui\ny t r [tu] i [yo] ptypo [ip] asappo [yp] tyu [po] jjh [dj] klkjjhj\nsdf [od] hhg [dh] pasd app o [ip] [oa] s [ra] [ts] [yd] [uf] [ig] [oh] p jf jf jf jf jf jf jf j"],
    ["Mass Effect 3 - Leaving Earth", "[30w] | [18w] | [29e] | [30r] |\n[37wk] k k k k [fk] k k | [59rk] k k k k k k k |\n[60tk] k k k k [fk] k k | [7yk] k k k k k k k |\n[370w] 3 | [1580] f k f |\n[370w] 3 | [370] f k f | [370] k k k k [fk] k [fk] | [59rk] k k k k k k [3k] |\n[3k] k k k k [fk] k [3fk] | [7yk] [rk] k k k k [7yk] [3k] | [3k] k k k k [fk] k [fk] |\n[150 fkx] | [26 fkx] t f k f | [37w] 3 |\n[37wok] k k k k [fk] k [fk] | [59rpk] k k k [ak] k k k | [60tsk] k k [rk] k [fk] [ek] [fk] |\n[7ydk] k k [rk] k k k k |\n[37wok] k k k k [fk] k [fk] | [59r ok] k k k k k k k | [60tpk] k k k k [fk] k [fk] |\n[7yak] k k k k k k k |\n[37wok] k k k k [fk] k [fk] | [59rak] k k k k k k k | [60tsk] k k k k [fk] k [fk] |\n[7ydk] k k k k k k k |\n[30kx] k k k k [fk] k [fk] | [18 kx] k k k k k k k | [6kx] k k k k [fk] k [fk] |\n[0ru kx] k k k k k k k | [0ru kx] k k k k [fk] k [fk] | 3"],
    ["Massive Attack - Teardrop", "e e u\ne y e [yu] e e u\ne y e y e 6 e u\ne y e [yu] 5 e u\ne y e [ye] 4 e u\ne y e y [yu] 5 e u\ne y e [ye] 6"],
    ["Matrix - Clubbed to Death", "5yoyPyoy 6yoysyoy 5yoydyPy 8PtYtpYsY 9yoyPodP\nhoPoDPh Phoao dagagosg DosDdyoyPyoy PtYPpty IowE\nyoyPodP hdJhzJv"],
    ["Max Payne - Theme", "t Y i o s o P o Y r\nW w y w y Y r\nt Y i o s o P o Y r\nW w y w y Y\n[ts] [YD] [ig] [oh] [sl] [oh] [PJ] [oh] [YD] [ra]\n[WO] [wo] [yd] [wo] [yd] [YD] [ra]\n[ts] [YD] [ig] [oh] [sl] [oh] [PJ] [oh] [YD] [ra]\n[WO] [wo] [yd] [wo] [yd] [YD]\n[qi] | [qi] [wo] [WO] [yd] [ra] [wo] [YD] [yd]\n[qi] | [qi] [wo] [WO] [yd] [ts] [ra] [ep] [ra] | [tosh]\n[ts] [YD] [ig] [oh] [sl] [oh] [PJ] [oh] [YD] [adgh]\n[WO] [wo] [yd] [wo] [yd] [YD] [ash]\n[ts] [YD] [ig] [oh] [sl] [oh] [PJ] [oh] [YD] [adgh]\n[WO] [wo] [yd] [wo] [yd] [YD] [to]"],
    ["Max Payne - Theme (v. 2)", "sDghlhJhDa\nOododDa\nsDghlhJhDa\nOododD as"],
    ["Max Payne 3 - Theme", "[18] t Y o s o P o Y [29] r W w [5wy] w y Y r [18t]\nY i o s o P o Y [29r] W w [5wy] w y Y [18]\n[ts] [YD] [ig] [oh] [SL] [oh] [PJ] [oh] [YD]\n[29ra] [WO] [wo] [18yd] [wo] [yd] [YD] [29ra] [ts]\n[YD] [ig] [oh] [sl] [oh] [PJ] [oh] [YD]\n[18ra] [WO] [wo] [5wyd] [wo] [yd] [YD]\n[4qi] [qi] [wo] [WO] [yd] [5wra] [wo] [YD] [yd]\n[4qig] [ig] [oh] [OH] [yd] [5wts] [ra] [ep] [ra] [18osh]"],
    ["Mercede's Lullaby - Pan's Labyrinth", "p o i o p P p\np o i o p P o\no i u i o p o\np o p P s p"],
    ["Metallica - Nothing Else Matters", "3 w r u r w\n3 w r u r w\n3 w r u r w\n3 w r u r a\n[3a] r w [ra] u r w\n3 w [ra] [rs] a p [ra] p o I u\n[6u] t u I u t\n6 t u u [6tu] [7u] [8tu] 8 t u t o u t\n[8tu] [9yu] 9 y I y u p u I r u\n3 o a f r w\n3 w r u r w\n3 w r u r w\n3 w r u r w I\n3 0 r u [ao] 9 e y 8 w [tu]\n3 0 r u [ao] 9 e y 8 w [tu]\n3 0 r u [ao] 9 p I y 8 o u t 5 o y r 7 I Y r\n3 w r u r w\n3 w r u r w\n3 w r o w u r w [capslock on] 9I [capslock off] 9 o y w [8o] y w 5 $\n3 w r o w u r w [9I] 9 y e w [8y] w y 5 $\n3 w r o w u r w [9I] 9 o y w [8y] y w 5 w [yo] [7er] I I r\n3 w r u r w\n3 w r u"],
    ["Metro 2033 - Main Menu Theme", "e u [us] [id] e i d a e u [ya] [us] e u a p e u [us] [id] e i d a [ra] [wo] [eup] uasap\ne u [us] [id] e i d a e u [ya] [us] e u a p e u [us] [id] e i d a [ra] [wo] [eup] uasap\ne p [of] [pg] e p f [fd] e p [id] [of] e p f [fd] e p [us] [id] e i d a [ra] [wo] [ts] [ya]\ne p [of] [pg] e p f [fd] e p [id] [of] e p f [fd] e p [us] [id] e i d a [ra] [wo] [ts] [ya]"],
    ["Metro 2033 - Main Menu Theme (v. 2)", "6 0 [0t] [qy] 6 q y r 6 0 [9r] [0t] 6 0 r e\n6 0 [0t] [qy] 6 q y r [7r] [5w] [60e] 0rtre\n6 e [wu] [ei] 6 e u [uy] 6 e [qy] [wu] 6 e u [uy]\n6 e [0t] [qy] 6 q y r [7r] [5w] [8t] [9r]"],
    ["Minecraft - Mice on Venuso", "[x8] z [whv] [egl] j [x8] z [ef]jl\nx [qgl] j [wadh] [wa] [5r] [efj]\n[gl] [whl] j [qgjl] [gj9] l [wkh] h [gd9] [qe] [wh0]\nf [qs] d s [esf] g h [qf] [qd] sd [esf] [h8] f [5d] [0f]\n[80x] zl [wh] z [gil] j\n[x8] z [fjl] [gl] [whl] j [qgl] [gj9] l [whk] h [gd9] [qe]\nTranscribed and Submitted by Brian"],
    ["Minecraft - Wet Handso", "6 0 e r T r e 0 9 Q T u T e\n6 0 e r T r e 0 9 Q T u T e\nO 0 e r T r [pe] 0 I Q T u T e\nu I O 0 e r T r [ea] S 9 [QI] T u T e\nS f [h5] 7 9 [GQ] [ed] Q [9p] [7a] 5 7 9 Q e\n[h5] 7 G Q [ed] Q [9p] [7a] 5 7 9 Q e\np [6u] 0 e r T r e 0 6 0 e r T\nu p S | [7yd] 9 Q S p [uf] [IG] 7 [9yd] Q e T\np S [5d] 7 S d e G S | r e\n[3r] % 7 0 W 0 7 %\n3 % 7 0 W 0 6 5 7 9 Q e Q 9 7 6 ( 0 e T r e 0\n3 % 7 0 W 3 % 7 0 W"],
    ["Mission Impossible - Theme", "5 5 6^ 8 5 5 4 4^\n5 5 6^ 8 5 5 4 4^\n[5w] [5w] [E6] [t8] [5w] [5w] [q4] [Q4]\n[5wo] [5wo] [EP6] [t8s] [5wo] [5wo] [qi4] [QI4]\nEw9 Ew* Ew8 6^ 8\nEwi Ewu EwY Yy\nJhd JhS Jhs Ps\nJhc Jhx JhZ Zz"],
    ["Mortal Kombat - Theme", "ppsp dp fd\nssfs hs fs\nooao so sa\niipi ai ap\nppsp dp fd\nssfs hs fs\nooao so sa\niipi ai ap\npppp os\npppp ou\npppp os\npppp ppp pp\npppp os\npppp ou\npppp os\npppp ppp pp\npfpspPpspPo\npfpspPpspPo\npfpspPpspPo\npfpsoooopp"],
    ["Mozart - Molto Allegro", "gff gff gff l lkj jhg gfd\nfdd fdd fdd k kjh hgf fds\nlkkzHkjf lkkzHkjf"],
    ["Mozart - Music for Angels", "jHjHjfjd pS | jHjHjfjd pSjHjIpHj\n9pdju pdp Sd[pf]Sa pOp\nupaS eu Sd[f6] 0T dS[a0] rO pSj Hj IpHj\n9pdju pdp Sd[pf]L[k0][fr] kLkjH[jf]\nupaS eu Sd[f6] 0T dS[a0] r[uj]kjHjpfp\njkjHjpfp | jkjHjkLzxLkjH a\njkjHjpfp | jkjHjpfp | jkjHjkLzxLkjH a\njkjHjpfp | jkjHjpfp | jkjHjkLzxLkjH a\n[0a]Ou pSjHjIpHj\n9pdju pdp Sd[pf]Sa pOp\nupaS upaS uSdf uSdf udSa udSa O pSj Hj IpHj\n9pdju pdp Sd[pf]L[k0][fr] kLkjH[jf]\nupaS upaS uSdf uSdf udSa uOa\njkjHjpfp\njjkjHj pfp | jjkjHj pfp | jjkjHjkLzxLkLkjH afa | jjkjHj pfp |\njjkjHj pfp | jjkjHj pfp | jjkjHjkLzxLkLkjH afa | jjkjHj pfp |\njjkjHj pfp | jjkjHj pfp | jjkjHjkLzxLkLkjH afa\n[0a]Ou pS [QT]HjH[uf]dOIT Sd[pf]Sa pOp\nupaS eu Sd[f6] 0T dS[a0] rO pSj Hj IpHj\n9pdju pdp Sd[pf]L[k0][fr] kLkjH[jf]\nupaS eu | Sd[f6] 0T dS[a0] ru [QTO]\n$*)W eTOp SHj LVbVL | $"],
    ["Mozart - Turkish March", "sapOps | dsasf | gfDfk\njHjk jHjk jHjl\nj l hjkjhj hjkjhj hjkjh G f\nfghhjhgfd | fghhjhgfd\nsdffgfdsa | sdffgfdsa\napOps dsasf gfDfk\njHjk jHjk jHjl\nj k l k j H j f g d s asasasasap\nfghhjhgfd | fghhjhgfd\nsdffgfdsa | sdffgfdsa\napOps dsasf gfDfk\njHjk jHjk jHjl\nj k l k j H j f g d s asasasasap"],
    ["Muhtesem Yuzyil", "d ddd h f g"],
    ["Murat Gogebakan - Ay Yuzlum", "gggh jJjh\nfffg hjhgh\nhhhj JJlJjJ\njjj jJjhjhj\nggfgfd Ddsd"],
    ["Naruto - Grief and Sorrow", "[sfj] u p a s k j h [sfo] o s d f h [ipd] e y u i s d s [tip] s [oa] [oy]\n[sfj] u p a s k j h [sfo] o s d f h [ipd] e y u i d s [tup]\n[eti] [eti] [tup] [ryo] [yu] [yo] [tup] u p s a [pou] u\n[eti] [eti] [tip] [ryo] [ryu] [ryo] [ryo] I [ryp] O j k x\n[tup] [psf] d s [tup] [psf] d f [dj] l k j h [sfh] [psf] [oad] [yoa]\n[tup] [psf] d s [tup] [psf] d f [dj] l k j h j [sfh] [psf] [oad] [yoa]\n[tup] [psf] d s [tup] [psf] d f [dj] l k j h j [oaf] [odk] [ofj]\n[tup] 0 e r t a p o [wtu] w t y u o [qey] 6 9 0 q y t [qe8] 4 [qe] 4 [qe] [wr9] [w9]\n[tup] 0 e r t a p o [wtu] w t y u o [qey] 6 9 0 q y t [e80]\ne r t u p a [pis] [piy] [pisy] [oua] [ou] u [oue]\na p o [ryu] [ryu] o [pis] [piy] [pisy] [oua] ou u [tio] [ti] o [tip] O j k x\n[tup] 0 e r t a p o [wtu] w t y u o [qey] 6 9 0 q y t [qe8] 4 [qe] 4 [qe] [wr9] [w9]\n[tup] 0 e r t a p o [wtu] w t y u o [qey] 6 9 0 q y t [qe8] p u s p j f j l x b"],
    ["Naruto - Nakama", "8 w y w u 8 w i w u 8 w y w u 8 w i w u\n[8f] w y w u g [9d] w y w t s [4s] 8 q e t d\n[8f] w t u o [ef] u p s h [qj] t i p g\n[8f] w t u g [wd] y o a [qj] t i p k j [0h] r u o f d f [9d] e y i s h\n[8f] w t l k h f h [qj] t i p k j [0h] r u o z x [ql] t i p j k l [wz] y o a x\n[8f] w y w u g [9d]w y w t s [4s] 8 q e t d\n[8f] w t u o [ef] u p s h [qj] t i p g\n[8f] w t u g [wd] y o a f d [8s] w t u o 8 w t u o u"],
    ["Naruto Shippuden - Yukimaru Theme", "j l z x j j l z v x x v b v x x z x k l j\n[6p] 0 e [0s] d 0 e f [4p] 8 q 8 e 8 q 8 [5p] 9 w [9s] [rd] 9 w h [8f] w t w u w [tf] h\n[6j] 8 q 8 e 8 [qh] f\n[6f] 0 e 0 t 0 e 0 [5d] 9 w [9f] [ea] 9 w s\n[6p] 0 e 0 t 0 e 0 6 0 r 0 t 0 e 0\n[6p] 0 e [0s] d 0 e f [4p] 8 q 8 e 8 q 8 [5p] 9 w [9s] [rd] 9 w h [8f] w t w u w [tf] h\n[6j] 8 q 8 e 8 [qh] f\n[6f] 0 e 0 t 0 e 0 [5d] 9 w [9f] [ea] 9 w s\n[6p] 0 e 0 t 0 e 0 6 0 r 0 t 0 e 0 [60p]"],
    ["Naruto", "[tj] [uk] o h y [GI] p f u o d a t [uj] [ho] G y p l [kp] [pkdw] [ru] [drjo]\n[tj] [uk] o h y [GI] y p f u o d a t [ju] [ho] G y p l [pkdw] [ru] [drjo]"],
    ["Ne-Yo - Madoo", "[tf] o s h l k w y o d k j h g 9 e i f s g q t i f s o\n[8u] w t o s a 5 9 w y a p o i 2 6 q u t i 4 8 q u t\n8 w t o a s a s 5 [9s] [ws] s s d f g [2f] 6 q s [4ips] 8 q [ip]\n[8uo] w t o a s a s 5 [9s] [ws] s s d f g [2f] 6 q s [4ips] 8 q o o p\n[6a] [0s] [ea] s a d s [5a] [9s] [wa] s a d s\n[2a] [6s] [qa] s o o p [4a] [8s] [qa] s a d s\n[6a] [0s] [ea] s s d s 5 [9s] [ws] s s d f g\n[2f] 6 q s [4o] [8p] [qa] s d h\nj [6ek] j g [5wh] g d [2qf] d [4qo] p a s d h\nj [6ek] j g [5wh] g d [2qf] d f d [4qf] d f d f d f g\n[8h] w t h h [5h] 9 w h h g f d 2 6 q g 4 8 q f g\n[8h] w t h h [5h] 9 w h h g f d 2 6 q g 4 [8s] [qs]\ns s a p a [8s] w t f h s 5 [9s] [ws] s s a p a [2s] 6 q f h s 4 [8s] [qs]\ns s a p a [8s] w t f h s 5 [9s] [ws] s s a p a [2s] 6 q f h s 4\n8 q 8 w t o a s a s 5 [9s] [ws] s s d f g [2f] 6 q s [4ips] 8 q [ip]\n[8uo] w t o a s a s 5 [9s] [ws] s s d f g [2f] 6 q s [4ips] 8 q o o p\n[6a] [0s] [ea] s a d s [5a] [9s] [wa] s a d s\n[2a] [6s] [qa] s o o p [4a] [8s] [qa] s a d s\n[6a] [0s] [ea] s s d s 5 [9s] [ws] s s d f g [2f] 6 q s [4o] [8p] [qa] s d h\nj [6ek] j g [5wh] g d [2qf] d [4qo] p a s d h\nj [6ek] j g [5wh] g d [2qf] d f d [4qf] d f d f d f g\n[8h] w t h h [5h] 9 w h h g f d 2 6 q g 4 8 q f g\n[8h] w t h h [5h] 9 w h h g f d 2 6 q g 4 [8s] [qs]\ns s a p a [8s] w t f h s 5 [9s] [ws] s s a p a [2s] 6 q f h s 4 [8s] [qs]\ns s a p a [8s] w t f h s 5 [9s] [ws] s s a p a [2s] 6 q f h s 4 8 q\n[tf] o s h l k w y o d k j h g 9 e i f s g q t i f s o\n[8u] w t o s a 5 9 w y a p o i 2 6 q u t i 4 8 q u t p 8 w t u o s"],
    ["Nightmare on Elm Street", "p d j H j f g p\np d j H j f g j J S\np d l z l J k g\np d j H j f g j J S"],
    ["Nokia - Tune", "fdIO[|]Sayu[|]apTupp"],
    ["Noyan - Hatirla Sevgili", "etuy tu\netuy tr\nrtytry\nurtytre"],
    ["Oasis - Stop Crying Your Heart Out", "G f d a\na G f d a\na G d a\np a p a a | d f a\na G f d a\nr I u y r |\na G f d a | u u y r\na G d a | r I y r\np d f G d | p d f G f\np d f G f d | p d f f d | d f G d\np d f G f d | f d f d G f a d\nG f d a | I u y r"],
    ["Onuncu Yil Marsi", "oapsapo ddadsap aas p asd\nff d as sa\nd d dDDdDd s sDdapp\npsapddf sapdsda"],
    ["Pearl Harbor - Theme", "w [tu] y t\n3 7 [0o] I u\n4 8 [qu] y t [80] w [wt]\nt y 6 0 [es] a u\n3 7 [0o] I r\n8 w [tu] y t [5t] 9 [wr] e w\n[9q] e [yi] u e q u y q 9 e [yi] u y [5wg] f d f\n8 w [tu] y t\n3 7 [0o] I u\n[8t] u y w [30] o I r 9 [eo] u e o r [ak]\n[8sl] w [tak] [sl] [uf] [3oh] 7 0 w\n3 7 [0oh] w [4pj] 8 [qoh] [pj] [ts] [8uf] w t [5wyd] 9 [wak]\n[6sl] 0 [eak] [sl] [uf] [3oh] 7 0 w\n3 7 [0oh] w [9ig] e [yuf] [yd] [ts] [5uf] 9 w r 5 9 w r [5yd] 9 w r [5ak]\n[8sl] w [tak] [sl] [uf] [3oh] 7 0 w\n3 7 [0oh] w [4pj] 8 [qoh] [pj] [ts] [8uf] w t [5wyd]\n[ak] [6sl] 0 [eak] [sl] [uf] [3oh] 7 0 w\n3 7 [0oh] w\n[9 e i g] [uf] [yd] [ts]\n[4 8 i g] [uf] [yd] [ts]\n[5 9 i g] [uf] [yd] [ts]\n[8ts] w [ts]"],
    ["Phantom of the Opera", "upu [2o] ii 5 y o y [6u]\nupu [2o] ii 5 y o y [6u]\n0 e t [2u] y y 5 y o y [6u]\nu [6p] o i u y t r e W\nq q 0 [60]"],
    ["Pink - Fckn Perfect", "w e [5r] y | u y [9e] | w e [5r] y u o o y [8u] |\nw e [5r] y | u y [9e] | w e [5r] y u o o y [8u] |\no I [5o] o o o p o I [9o] o o p o I [0o] o o p o y [8t] |\no I [5o] o o o p o I [9o] o o p o I [0o] o o p o y [8t] |\n[5o] p o p a | p o [9o] p o p a | o p [0o] u o p [8o] u |\n[5o] p o p a | p o [9o] p o p a | o p [0o] u u o p [8o] u u y 5"],
    ["Pirates of the Caribbean", "9 99 9999 9 99 9999 9 99 9999 9 99 9999 9yI\netyy yuii iouu ytty\netyy yuii iouu yty\netyy yioo opPP popy\nyuii opy yiuu iyu\nyuio p iye P iyE eEe\npppPp oooop\nppppPp iiuy\nyui opo iuiopo iop pou iuyuty\nyui uio iopoiy yuiopP yoi ouy\nu t p P p p p p o o i u i u T\np P p p s p o o i u i u T\nyui opo iuiopo iop pou iuyuty\nyui uio iopoiy yuiopP yoi ouy\netyy yuii iouu ytty\netyy yuii iouu yty\netyy yioo opPP popy\nyuii opy yiuu iyu\nyuii opy yiuu yty"],
    ["Pokemon - Intro", "dddd d s p i | i d d s P s\nDDDD D d s P | P d d s P d\ndddd d s p i | i d d s P s\nDDDD D d s P | P d d s P d\ndgh dgh hg d s P\nggh J j h g\ndgh hh h g d s\nPP dd s P d\ndgh dgh hh g h g\nhh j J j h g\nd D JJ ddd JJ J J J j\nddghh ddgh J h dg[dh]"],
    ["Pokemon - Lugia's Song", "f h G d f a |\nf h G a f h k | x z k h jkj f |\nf k j d Gfdf |\nf h G d f a |\nf h G | a f h k | x z k h jkj f |\nf k j d Gfdf |\nf h G d f a |\nf h G z kjk | x z k h | jkj f |\nf k j d Gf |\nf h G d f a |\nf h G d apa | x z k h jkj f |\nf k j d Gfdf"],
    ["Portal - Still Alive", "gfddf ogfddfsdo dfgdasd oof\nogfddf ogfddfsdo dfgdasdosdDdsP\noOPDdssPsPPP oOPDgDDdsdDD\nghHHhg DghhgD sPsDDd dff\ngfddf ogfddfsdo dfgdasd oof\nogfddf ogfddfsdo dfgdasdosdDdsP\noOPDdssPsPPP oOPDgDDdsdDD\nghHHhg DghhgD sPsDDd dff\nzxzkh jkk\nzzzxzkhjkk zzzxzkhjkk\nzzxzkhjkk zzzxzkhjkk\nlzz lkk"],
    ["Rain and Tears", "[tf] o s f [wd] o a d [es] u p s [0a] u o a [qp] i p s [to] u o s [qp] i p s [wa] y o a\n[tf] o s o [wh] o a o [el] s j s [0f] o a h [is] p s j [ts] o s f [qg] f g f [wh] o a o\n[tf] o s o [wh] o a o [el] s j s [0f] o a h [is] p s j [ts] o s f [qg] f g a [ts] o s o\n[yd] p d f [ep] u p f [0f] o a o 0 o a f [yd] p d f [0f] o a o 0 o a f\n[yd] p d f [ep] u p f [ep] 0 o a o w o a o\n[tf] o s o [wh] o a o [el] s j s [0f] o a h [is] p s j [ts] o s f [qg] f g f a [osf]"],
    ["Renesmee's Lullabyo", "[wtu] f a [ru] f k [eti] j k l k j [ryk] h [etuk]\n0 e t [uf] a u t e 0 W\nr [uf] k u r W q e t [ij] k l [ik] t [ej]\n[wk] r y [oj] [ek] r u f\n[etih] h g g [wruf] f d d [0ruf] f a f [rua] f\n[etih] h g g [wruf] f d d [0ruf] k | [ru] f\n[8wsh] [sh] [sg] [sg] | [80pf] [pf] [pd] [pd] | [0af] [af] a [af] [0a] f\n[8wsh] [sh] [sg] [sg] | [80pf] [pf] [pd] [pd] | [0af] [fk] [ru]\n[etu] f a [etu] [etu] [wtu] f k\n[wtu] [wtu] [qtu] j k l k [qtu] [qtuj] [0k]\nH 0 0 9 p u 9 0 [8q]\np f [8q] 0 [80] s [8f] [9d] s [0f] | d s a | u\n[8wo] o i i [80u] u y y [0u] u r u [0r] u\n[8wo] o i i [80u] u y y [0u] u a [ru]\nu [8wto] [to] [ti] [ti] [80eu] [eu] [ey] [ey] [0ru] [ru] r [ru] [0r]\nu [8wto] [to] [ti] [ti] [80eu] [eu] [ey] [ey] [0ru] i u y t r"],
    ["Renesmee's Lullabyo (v. 2)", "[wtu] f a [ru] f k [eti] j k l k j [ryk] h [etuk]\n0 e t [uf] a u t e 0 W\nr [uf] k u r W q e t [ij] k l [ik] t [ej]\n[wk] r y [oj] [ek] r u f\n[etih] h g g [wruf] f d d [0ruf] f a f [rua] f\n[etih] h g g [wruf] f d d [0ruf] k | [ru] f\n[48wsh] [sh] [sg] [sg] | [3680pf] [pf] [pd] [pd] | [370af] [af] a [af] [70a] f\n[48wsh] [sh] [sg] [sg] | [3680pf] [pf] [pd] [pd] | [370af] [fk] [ru]\n[etu] f a [etu] [etu] [wtu] f k\n[wtu] [wtu] [qtu] j k l k [qtu] [qtuj] [370k]\nH [370] [370] [269] p u [269] [370] [48q]\np f [48q] 0 [1580] s [158f] [269d] s [370f] | d s a | u\n[48wo] o i i [3680u] u y y [370u] u r u [70r] u\n[48wo] o i i [3680u] u y y [370u] u a [ru]\nu [48wto] [to] [ti] [ti] [3680eu] [eu] [ey] [ey] [370ru] [ru] r [ru] [70r]\nu [48wto] [to] [ti] [ti] [3680eu] [eu] [ey] [ey] [370ru] i u y t r"],
    ["Requiem for a dreamo", "[6e] [6e] [6e] [6e] 4q 4q 30 30 [6e] [6e] [6e] [6e] 4q 4q 30 30\n[6t] r [6e] 0 [6t] r [6e] 0 [4t] r 4e 0 [3y] t [3r] t\n[6t] r [6e] 0 [6t] r [6e] 0 [4t] r 4e 0 [3y] t [3r] t\n[6t] e 6 t e [6t] e 6 t e [4t] e 4 t e [3t] e t e [3t] e\nt e [6t] r t 6 t r [6t] r t 6 t r\n[4t] r t 4 t r [3t] r r r [3t] r r r\n[6t] e 6 t e [6t] e 6 t e [4t] e 4 t e [3t] e t e [3t] e\nt e [6t] r t 6 t r [6t] r t 6 t r\n[4t] r t 4 t r [3t] r r r [3t] r r r\n[6t] r r e [6e] t t [6t] r r e [6e] t t\n[4t] r r e 4e e e e 3e r r r [3r] t t t\n[6t] r [6e] 0 [6t] r [6e] 0 [4t] r 4e 0 [3y] t [3r] t\n[6t] r [6e] 0 [6t] r [6e] 0 [4t] r 4e 0 [3y] t [3r] t\n[6t] e 6 t e [6t] e 6 t e [4t] e 4 t e [3t] e t e [3t] e\nt e [6t] r t 6 t r [6t] r t 6 t r\n[4t] r t 4 t r [3t] r r r [3t] r r r\n[6t] e 6 t e [6t] e 6 t e [4t] e 4 t e [3t] e t e [3t] e\nt e [6t] r t 6 t r [6t] r t 6 t r\n[4t] r t 4 t r [3t] r r r [3t] r r r\n[6t] r [6e] 0 [6t] r [6e] 0 [6t] r [6e] 0 [6t] r [6e] 0\n3 6 0 e t u"],
    ["Richard Clayderman - Ballade pour Adeline", "[18t] osd tosd tosd tosd |\n[8t] ofo tofo tofo tofo[8f] w [uf] w u | w [uof] w [pg] [9pg] |\ne [ipg] e i [pg] [epg] [pg] [ipg] [pg] [epg] [ah] [wah] |\ny [ah] y a y | [ah] [sj] [8of]w u w u w u 5 [1of] w [uof] w u |\n[of] [wof] [of] | [8of] [of] [wof] [pg] [2pg] | e [ipg] e 9 [pg] [epg] [9pg] [9pg] |\n[9pg] [9pg] [ah] [wah] | y [ah] y a d [ah] j [8of] | w u w 8 w [tu] |\n| e u [ulx] f [skz] f [jl] f 0 r |\n[hk] a [ogj] a [fh] a [qgj] t i t [wah] r [og] r |\n[8u] ofoy odot oso[r7] o 6 0 |\n| [sf] u [tad] u [ps] u 3 7 |\n[oa] r [wip] r [uo] u [48ip] | o i o | 5 wty wtyi wyio wyio |\n[5y]iod [wy]iod | [5o]dgh [wo]dgh |\n[5d]ghz [wd]ghz | [5h]zcv [wh]zcv | [5h]kzv [1h]kzv |\n[1hx] w | [uhx] w 8 w [uhx] w [jc] [2jc] |\ne [ijc] e 9 [jc] [ejc] [jc] [ijc] [jc] | [ejc] |\n[kv] [wkv] y [akv] y a d [kv] [lb] [shx] |\n[DJV] Z J h [ijc] l j h [pdw] a o 5 |\n[18hx] w [uhx] w 8 [w8hx] [w8hx] [w8hx] |\n[w8hx] [w8hx] [w8hx] [w8hx] |\n[kv] [wkv] y [akv] y a d h [kv] | [lb] [tkx] |\no f h s j l c v [av] k z b [thx] | o f h s j l c v [av] k z b | [18hx]"],
    ["Right Here Waiting", "toshhgfd wyoddfgf eussdfdsapo\n8wtooiuy 59wyyuiu 60ttyuiuytrt\nshlvvcxz odhzzxcx pfllzxzlkjh\ntoshhgfd wyoddfgf eussdfg q gfd w sas"],
    ["Rihanna - Take a bow", "u a f k r a D f T f j e H G f\nu a f k r a D f T f j e H G f\nu a f k r a D f T f j e H G f"],
    ["Sailor Moon - Intro", "wtY oo ii Y y i\nadgHHJHhgDh\nosDllkkJH\nHhghgDds\nwtY oo ii YY y i\nadgHHJHhgDh\nosDllkkJH\nHhghgDds\nd J l zz l J j hghj\nd J l zz l J j\nwtY oo ii Y y i\nadgHHJHhgDh\nosDllkkJH\nHhghgDds\nHhghgDds\nHhghgDds"],
    ["Saw - Theme", "yui yuiuyuio\nyui yuiuyuio\nyui iop pas\nyui iop popas\nyui iop popas\niuyuyty u i o poioiuy yyuuiiou yiou"],
    ["Scott Joplin - The Entertainero", "[dz] [fx] [sl] [pj] [ak] [oh]\n[yd] [uf] [ts] [ep] [ar] [wi]\n[9y] [0u] [8t] [6e] [7r] [6e] W [5w] | [oadh]\ny Y u s u s u s\n[sfl] [dgz] [DGZ] [fhx]\n[sfl] [dgz] [fhx] [adk] [dgz] [sfl]\ny Y u s u s u s\n[psj] [osh] [GI] [pj] [sl] [fx] [zd] [sl] [pj] [dgz]\ny Y u s u s u s\n[sfl] [dgz] [DGZ] [fhx]\n[sfl] [dgz] [fhx] [adk] [dgz] [sfl]\n[sl] [dz] [fx] [sl] [dz] [fx]\n[sl] [dz] [sl] [fx] [sl] [dz] [fx]\n[sl] [dz] [sl] [fhx] [sfl] [dgz] [fhx] [adk] [dgz] [sfl]"],
    ["Secret Gardeno", "u p a [6s] 0 t a [9s] e u [5s] [9d] [ra] p [0a] w u\na [qa] s [ep] o [9p] e i o [0u] 7 0 Q W\nu p a [6s] 0 t a [9s] e i [5s] [9d] [ra] p [0a] w u\na [qa] s [ep] o [9p] e i o [6p] 0 e r t\nu p a [6s] 0 t a [9s] e u [ws] [9d] [ra] p [0a] w u\na [qa] s [ep] o [9p] e i o [0u] 7 0 Q W\nu p a [6s] 0 t a [9s] e i [ws] [9d] [ra] p [0a] w u\na [qa] s [ep] o [9p] e i o [6p] 0 e r t\na s h [qj] t p [9j] k [rh] [ig] [0h] r o\na 6 [0a] [rs] [uf] [9g] e i p [5g] [9h] [af] [os] [0f] u r e\na [5s] h [qj] t p [9j] k [rh] [ig] [0h] r o\na [6h] j [rg] [uf] [9g] e i p [5g] [9h] [af] [od] [0f] r u I O\nu p a [6p] 0 e r t [qa] s [ep] o [9p] e i o [6p] 0 e r [6tup]"],
    ["Shakira - Waka Waka", "9 yyyuy 6 TTTyT 7 yyTr ryyT r [r7] yyyury\n9 yyyuI 6 TTTyu 7 yyTr yyT [r5] ryyyury\n9 yu yI 6 ypI 7 yyuyI 5 ypI\n[y9] yyyy uy [u6] uuuIyu 7IIIyy r rrp 5 Iyyryy\n[y9] yyyy uy [u6] uuuIyu 7IIIyy r rrp 5 Iyyryy\n[y9] yyyy uy [u6] uuuIyu 7IIIyy r rrp 5 Iyyryy"],
    ["Sherlock Holmes", "[2p] [9qep] O [2p] [9qe] P\n[2a] [9eq] i [2a] [9qe] s\n[2p] [9qp] O [2p] [9qe]\n[29] [9eu] [29] [9eu] [29] [9eu] [29] [9eu]\ne [e W e] [e W w] [w Q w] [w W e] [e W e] [e W w] [w Q w] q 0 9 [yp] [yp]\ne [e W e] [e W w] [w Q w] [w W e] [e W e] [e W w] [w Q w] q 0 9 [yp] [yp]\np [p O p] [p O o] [o I o] [o O p] [p O p] [p O o] [o I o] i u y [yp] [yp]"],
    ["Silent Hill - Promiseo", "6 0 t 0 6 q t q 8 w t w 9 w r w\n6 0 t 0 6 q t q 8 w t w 9 w r o\n[f6] 0 t 0 6 q t [qh] [s8] w t w 9 w r o [f6] 0 t 0 6 q t s [o8] w t w 9 w r w\n6 0 [th] [0h] [6h] [qg] [tf]q 8 w t [wd] [9d] [wf] d s\n6 0 [th] [0h] [6h] [qj] [th]q 8 w t [wl] [9l] w [rk] w\n6 0 [th] [0h] [6h] [qg] [tf]q 8 w t [wd] [9d] [wf] d s\n6 0 [th] [0h] [6h] [qj] [th]q 8 w t [wl] [9l] w [rk] w\n[6l] 0 t k [6j] q [tk] [ql] [8z] w [tl] w k w r w\n[6l] 0 t k [6j] q [tk] [ql] [8z] w [tl] w k w r w\n6 0 t 0 6 q t q 8 w t w 9 w r w 6 0 t 0 6 q t q 8 w t w 9 w r w 8"],
    ["Silent Hill - Theme", "w y d y Y o [dv] o [uh] o s o Y o [sz] o\nw y d y Y o [dv] o [uh] o s o Y o [sz]\n[wo] p P s [od] s [PY] poo [od] p P [su] P p o [PY] p o i p o"],
    ["Skylar Grey - Coming Home", "e y T [r5] | e y T [e9]\ny T [r7] | r y T [e6]\ny T [r5] | y T [e9]\ny T [r7] | r y T [e6]\ne y u I y u [e9]\ne y u I u y u T\ne y T [r5] | e y T [e9]\ny T [r7] | r y T e"],
    ["Sleeping", "f f [hu] o # f f [hu] o # f h [lu] [ko] j [ji] [hu]\nd f [gy] [do] # d f [gy] o # d g [ky] j [ho] [ki] [lu] o\ns s [psl] t # j g [ush] t # f s [psg] [oh] [ij] [osf] t\ns s [pl] t # j g [uh] t # f s [pg] h g [of] [id] [us] o\nf f [uh] o # x x [flv]"],
    ["Slumdog Millionaire - Latika's Themeo", "[8td] f d f d a s [6ed] f d f d a s\n[4qd] f d g d a s [5wd] f d\n[8to] s a s [6es] a p o [4qo] s a s [5w]\n[8to] s a s [6es] a p o [4qo] s a s [5wa]\n[8dh] u o a s [6af] u a s [4j] o a s [5dk] o s a\n[8dh] u o a s [6af] u a s [4j] o a s [5dk] o s a\n[8td] [8f] 8 [8d] [8f] [8d] [8a] [8s] [6ed] [6f] 6 [6d] [6f] [6d] [6a] [6s]\n[4qd] [4f] 4 [4d] [4g] [4d] [4a] [4s] [5wd] [5f] 5 [5d] 5 5 5\n[8to] [8s] [8a] [8s] 8 8 8 [6es] 6 6 [6a] [6p] 6 [6o] 6\n[4qo] [4s] [4a] [4s] 4 4 4 [5w] 5 5 5 5 5 5 5\n[8to] [8s] [8a] [8s] 8 8 8 [6es] 6 6 [6a] [6p] 6 [6o] 6\n[4qo] [4s] [4a] [4s] 4 4 4 [5wa] 5 5 5 5 5 5 5\n[8dh] [8u] [8o] [8a] 8 [8s] 8 8 [6af] 6 [6u] [6a] [6s] 6 6\n[4j] 4 [4o] [4a] [4s] 4 4 [5dk] 5 5 [5o] [5s] [5a] 5 5\n[8dh] [8u] [8o] [8a] 8 [8s] 8 8 [6af] 6 [6u] [6a] [6s] 6 6\n[4j] 4 [4o] [4a] [4s] 4 4 [5dk] 5 5 [5o] [5s] [5a] 5 5 5 5\no [8ts] [8ts] a d [6es] [6es] a d [4qs] [4qs] a d [5ws] 9 w u\no [8ts] [8ts] a d [6es] [6es] a d [4qs] [4qs] a d [5ws] 9 w u\no [8ts] [8ts] a d [6es] [6es] a d [4qs] [4qs] a d [8tus] 1"],
    ["Smurfs - Theme", "wew0ww opouoo hjhfhh hhjjk\nopouoi ioiyiu uiutuy yuyryt\nopouoi ioiyiu uiutuy yuyryt twt"],
    ["Somewhere Over The Rainbow", "[1t] 5 8 0 [6s] 0 e t [3a] 7 o p a w s (E) [4t] 8 q e p e t i\n[3o] 8 0 w u w t w [4e] 8 q e i (W) t i [1u] 5 t y [6u] 0 i w\n[2y] 6 r t [5y] 9 u q [1t] 5 8 0 t\n[1t] 5 8 0 [6s] 0 e t [3a] 7 o p a w s (E) [4t] 8 q e p e t i\n[3o] 8 0 w u w t w [4e] 8 q e i (W) t i [1u] 5 t y [6u] 0 i w\n[2y] 6 r t [5y] 9 u q [1t] 5 8 0 t\no [8u] o [wu] o [eu] o [wu] o [9i] o [qi] o [wi] o [ri] o p 8 0 w p 0\nw t y r w q 9 o [8u] o [wu] o [eu] o [wu] o I p I p I p I p a\n9 q e a q e t d 9 8 6 e 5 4 2\n[1t] 5 8 0 [6s] 0 e t [3a] 7 o p a w s (E) [4t] 8 q e p e t i\n[3o] 8 0 w u w t w [4e] 8 q e i (W) t i [1u] 5 t y [6u] 0 i w\n[2y] 6 r t [5y] 9 u q [1t] 5 8 0 t\no [8u] o [wu] o [eu] o [wu] o [9i] o [qi] o [wi] o [qp] a [8s] [wu] [to] [us]\n[of] [sh] [fl] [hx] [lxvm]"],
    ["Star Wars - Across The Stars", "pg d hgfg d gfdf s d s p\npg d hgfg d gfdf s d s p\npg ddfgh f jh j jlJjhhjJJh\nhlJj hGjz\n[zd]\n[gc] [ZD] [zd] [sl] [sl] [zd] [ZD] [ZD] [sl]\n[gc] [ZD] [zd] [sl] [ak] [dz] [hv]"],
    ["Star Wars - Celebration Themeo", "d h z l | JjJ | jhj d h | z h\nd h z l | JjJ | jhj d h |\nd h z j J l | JjJ jhj d h | z h\nd h z j J l | JjJ jhj d h | z h\n[wo] [EP] [oh] | [ig] | [yd] |\n[wo] [EP] [yd] [oh] [ig] [yd] [ts] [ts] [EP] [ep] [wo]\n[wo] [EP] [yd] [oh] | [ig] | [yd]\n[wo] [EP] [yd] [YD] [yd] [ts] [EP] [ts] [ep] | w\nd h z l | JjJ | jhj d h | z h\nd h z j J l | JjJ jhj d s h\n[oh] [PJ] [dz] [hv] | [gc] | [dz]\n[oh] [PJ] [dz] [hv] [gc] [dz] [sl] [sl] [PJ] [pj] [oh]\n[oh] [PJ] [dz] [hv] | [igc] | [dz]\n[oh] [PJ] [dz] [DZ] [dz] [sl] [PJ] [sl] [pj] [oh]\n[oh] [PJ] [dz] [ohv] | [igc] | [dz]\n[oh] [PJ] [dz] [hv] [jb] [hv] [gb]\n[DB] | [ig] | [gv] v b [JB] v b B b v"],
    ["Star Wars - Imperial March", "[5yo] [5yo] [5yo] [EY] P [5yo] [EY] P [5yo]\n5 d d d [PD] [P] [YI] [EY] P [5yo]\n5 [5odh] [5o] o [5odh] G g f D f\nO [TOS] s a [IP] p [IP] Y [YI] [EY] I P o P d\n5 h o o [5oh] [DG] g f D f\nO [TOS] s a [IP] p [PI] Y [YI] [EY] P [5yo] [EY] P [5yo]"],
    ["Star Wars - Intro", "www t o iuy s o iuy s o iuiy\nwww t o iuy s o iuy s o iuiy\nwwe e iuyt tyuy er\nwwe e iuyt o yy\nwwe e iuyt tyuy er\nwwe e iuyt o yy"],
    ["Star Wars - The Forceo", "9 w e E t E 9\n9 w e E 9 E 9 y t\n[y9] [wo] [ep] [EP] [st] [EP] [y9]\n[y9] [wo] [ep] [EP] [y9] [EP] [wo] [yd] [st]\ny [yo] [yp] o [do] P [yh]\n[EP] [ep] [wo] [dyo] [EP] [wo] [ey9] [ey9] [wyo]"],
    ["Star Wars - The Forceo (v. 2)", "y o p P s P y\ny o p P y P y d s\n[dy] [ho] [jp] [JP] [sl] [JP] [dy]\n[dy] [ho] [jp] [JP] [dy] [JP] [ho] [zd] [sl]\nd [dh] [dj] h [zh] J [dv]\n[JP] [jp] [ho] [zdh] [JP] [ho] [dyp] [dyp] [dho]"],
    ["Star Wars - The Forceo (v. 3)", "r u I opo r\nr u I o r ouap\nr u I o u a o f\nu oIua our r u"],
    ["Stratovarius - Forever", "[lse] [xf] [zd0] [ls] [ka] [jpq] [ho] [gi] [fu5]\n[fu] [gi9] [ho] [jpq] [ls] [kaw] [jp6] [fu] [ls] [ka] [jp]\na [s6] 0 e t [fu] e t u [d3] 7 0 w [sr] 0 [aw] r [p4] 8 q e t q [oe] [it] [o1] 5 8 9 0 w t [tw]\n[i2] 6 9 q [ue] 9 [yq] e [a3] 7 0 w [or] 0 w r [p6] 0 [ae] t [su] e t u [a3] 7 0 Q W r y\n[s6] 0 e t [fu] e t u [d3] 7 0 w [sr] 0 [aw] r [p4] 8 q e t q [oe] [it] [o1] 5 8 9 0 w t w\n[i2] 6 9 q [ue] 9 [yq] e [a3] 7 0 w [or] 0 w r [p6]\n[l0] [ke] [jt] [fu] [de] [st] [au] [put]\ns a [peq] | [ae] s [dw9] | [fw9] g [hw0]\n[dw9] d [se0] | [se0] a [peq] | [ae] s [dw9]\n[sw9] a [s6] 0 [ae] t [pu] e t u [ute]\nu s a [pe] | [ae] s [dw9] | [f81] [g92] [h03]\n[d92] d [s81] | [se0] a [peq] | s [dw9]\na [s6] 0 [pe] t [pu] e t u [ute]\n[ls6] 0 e t [xfu] e t u [zd] 7 0 w [lsr] 0 [kaw] r [jp4] 8 q e t [ho] [gi] [fu1] 5 8 9 0 w t w\n[gi2] 6 9 q [fue] [dyq] [ka3] 7 0 w [hor] 0 w r [jp6] 0 [kae] t [lsu] e t u [ka3] 7 0 Q W r y\n[ls6] 0 e t [xfu] e t u [zd] 7 0 w [lsr] 0 [kaw] r [jp4] 8 q e t [ho] [gi] [fu1] 5 8 9 0 w t w\n[gi2] 6 9 q [fue] 9 [dyq] e [ka5] 9 w r [hoy] w r y [jp6] 0 e t u e t u [ute]\ns a [s6] 0 [pe] t u e t u [ute]\ns a [peq] | s [dw9] | a [se8] [aw9] [pe] | [e08] [6ep]"],
    ["Super Mario Bros", "fff s f h o\nspupa Ppo fhjghfsda\nspupa Ppo fhjghfsda\nhGgDf Opspsd\nhGgDf lll\nhGgDf Opspsd [DO] [id] [us]\nssssdfspo ssssdf\nssssdfspo fffsfho\nfsoopggpajjjhgfspo fsoopggp [ao] [dg] [dg] [dg] [sf] [ad] [os] u"],
    ["Super Mario", "uuutuo w\ntw0 ereW wuopi out yr\ntw0 ereW wuopi out yr\noIiYu tty\noIiYu sss\noIiYu tty Y y t\ntttty utew\ntttty u\ntttty utew\nuuutuo w\nutw weiie rpppoi utew\nutw weiie riiiuyt eww"],
    ["Superman - Theme", "o o t o o s o t\no o t o o s o t [of] [od] [od]\n[ow] [ow] [t8] [ow] [ow] [st] [ow] [t8]\n[ow] [ow] [t8] [ow] [ow] [st] [ow] [t8] [of] [od] [od]\nsss [di][di][di] H hh"],
    ["Swan Lake", "e t u t\ne t u t\ne t u t\ne t u t\n[ef] t u t [ep] [ya] [is] [yd]\n[ef] t u [ts]\n[ef] t u [ts]\n[ef] t u [tp] [qs] [ep] [0u] s\n[ep] t u t e [yd] [is] [ya]\n[ef] t u t [ep] [ya] [is] [yd]\n[ef] t u [ts]\n[ef] t u [ts]\n[ef] t u [tp] [qs] [ep] [0u] s\n[ep] t u t e"],
    ["Tchaikovsky - Swan Lake", "I rTyuI yI yI I ryrwyr Gda\nI rTyuI yI yI I ryrwyr rTyuIop oI opa po paS aI yTrTyuIop oI opa po pa [tus] o [tu] os [TOS] O [TIPS][ry][ru][ry][ry][yo][EP][rya][ruoa][ry][ry][ry][ry][yo][EP][rya] r Tyu Iop oI opa po pa[TS] [ra]IyTrTyu Iop oI opa po pas[tuos][tuo][tuos] [ruoa] [ruoa][ETIP] [ETI] [ETIP] [ETIP] [tuos][tuo][tuos][ruoa] [ruoa][ETIP][ETI][ETIP][ruoa] [ruoa][tuos] [tuo] [tuos][tuos][tuo] [tuos][uo] [ETI] [tuos][tuo] [tuos] [tuos] [tuo][tuo] [tuos][uo] [ETI] [tuos] [tuo] [tuos] [tuos][tuo] [tuos][uo] [uo] [yid] [EP][yid] [yid] [EP][yid] [yud] [yud] [TIPS] [yud] [TIPS] [yud][TS] [IG][of]SPIuTEQ0(&[ad] aSdf[IG] [yd] [IG] [yd] [IG] [yd] [ra] [QI] [yd] [ra] I u I rTyuI yI yQ"],
    ["Teoman - Istanbulda Sonbahar", "pppsf fjhghg glkhkj\nfffgfsfd aaasapgf\nggfg j ffdf p ggfg jhgf\nggfg j ffdf p ggfg jhgf\nggfg j ffd fp ssag ffpp\njkjg jkjf jkjg hgdf\njkjg jkjf jkjg hgdf"],
    ["Terminator - Theme", "yui u t q\nyui u t p o\nyui u t w\nq\n9 qq 0"],
    ["TES - Morrowind", "tyY Yio oPi oiYyt\ntyY Yio oPs Pds\nsdDdsPOoi\nYoi Yyt"],
    ["TES - Morrowind (v. 2)", "[up][oa][ps] [ps][ad][sf] [sf][sf][sh] [sf][sh][od] fd [os] a p [ip]\n[up][oa][ps] [ps][ad][sf] [sf][fh][gj] [fh] k [dj]\n[fj][hk][jl] k j [sh] g f [od] s f [od]\n[os]a[ip] [is][ua][ep]"],
    ["TES - Oblivion - Auriel's Ascensiono", "[fu] h G [fo] | [pd] a [fo] | f k [fu] | y\n[ft] [hr] G [fe] | [de] [aw] f | [fr] h [at]\n[u0] o I [uw] | [ey] r [uw] | u a [u0] | 9\n[u8] [o7] I [u6] | [y6] [r5] u | [u7] o [r8]\nu o I u | y r u | u a u\nu o I u | y r u | u o r"],
    ["TES - Oblivion - Glory of Cyrodiil", "﻿h l z Z | z l z | l J l | J h\nh l z Z | z l z | l J l | J l\no s d D | d s d | s P s | P o\no s d D | h g | D d\no s d D | d s d | s P s | P o\no s d D | d s d | s P s | P s\no o i | i o O d a\no o i | i o O d a | D d"],
    ["TES - Oblivion and Skyrim", "ert tyu uoy uytre\nert tyu uop o ap\np a s a p o i u y t uy\ntrt trt rtytre\newe ewe wertre\nert tyu uoy uytre\newe ewe wertre"],
    ["TES - Skyrim - Themeo", "r r r [wr9] r r [er0] r r [r0] r T y\nr r r [wr9] r r [er0] r r [r0] r T y r T y\n7 # 0 # [r79] # [e0] [r7] [u0] [ar] [ST]\na a a a [ao] [ao] [ao] [ao] [ao] [ap] [ap] [ap] [au] [au] [au]\na a a a [ao] [ao] [ao] [ao] [ao] [ap] [ap] [ap] [au] [au] [au]\n[ad] [ad] [ad] [ad] [ad] [ad] [ad] [ad] [ad] p p p [au] [au] [au]\n[ad] [ad] [ad] [ad] [ad] [ad] [ad] [ad] [ad] p p p\n[au] [ST] [dy] # [dy] [fu] [GI]\n[GI] [jp] [upf] # [dy] [ST] [aru]\n[ar] [ST] [dy] # [dy] [fu] [GI]\n[GI] [jp] [adk] # [jp] [SL] [ak]\n[ar] [ST] [dy] # [ST] [ar] [ep] # [wo] [QI] [u0] # [y9] [QI] [u0]\n[dy] [ST] [dy] # [dy] [ST] [dy] # [dy] [ST] [fu] [dy] [ST] [ary]\n[ary] [ep] [ary]\n[ar] [rp] [aru] [ep] [ar] [ST] [dy] [ep] [ary]\n[ST] [dy] # [dy][dy] [fu] [GI]\n[ST] [dy] [fu] [dy] [ST] [ary]\n[ar] [ep] [ary] [ep] [ar] [ST] [dy] [ep] [ary]\na S d S S [GI]\nr T y I [aryo]\ny [ak] [jp] [ho] [GI] [fu] [u0] # 0 r [u0]"],
    ["Tetris", "u rty tre etu ytr rty ut ee y ip oiu tuytr rty u te"],
    ["Tetris (v. 2)", "f asd sap psf dsa asd fs pp d gj hgf sfdsa asd f sp	"],
    ["Tetris (v. 3)", "0 789 876 680 987 789 08 66 9 qe wq0 80987 789 0 86	"],
    ["The Beatles - Let It Be", "[tuo] 1 [tuo] 1 [ryo] 5 [ryo] 5 [etu] 4 [etu] [wtu] [qeu] 2 [qey] 2\n[wtu] 1 [wtu] 1 [wry] 5 [wry] 5 [tip] 1 [tuo] [tyi] [1tu]\no o o o p u o o [s d] [f f] f d d s s [f f] g [f f] d f d s [0wt] [9qt] [80t] |\n[wtu] [wtu] [wry] [wry] [etu] [etu] [qet] [qet] [wtu] [wtu] [wry] [wry] [qet] [0wt] [9qt] [80t] |\no [1o] o p [1u] [3o] [5o] [5s d 5] [6f f f] [5d d] [4s] s 4 3 [1f] [f 1g] [3h] f [5d] 4 5 [f d] [5s] 321 |\no o o o p u o o [s d] [f f] f d d s s [f f] g [f f] d f d s [30] [29] [18] |\nf d [ups] [sf] h [adj] h h [ipf] d s [p o] [osf] s [osf] f [psh] f [osf] d f d [30] [29] [18] |\n[sfj] [sfh] [sdg] [osf] [iad] [ips] [yoa] [ops] [uos]"],
    ["The Beatles - Yesterday", "oii paSdfgf dd ddsPpoP pp oipoyi pp\noii paSdfgf dd ddsPpoP pp oipoyi pp\np p dfgfd fdsd p\np p dfgfd fsPf g s P p\noii paSdfgf dd ddsPpoPpp oipoyi pp\np p dfgfd fdsd p\np p dfgfd fsPf g\noii paSdfgf dd ddsPpoPpp oipoyi pp\nipoyi pp"],
    ["The Beatles - Yesterday (v. 2)", "[oh][ig][ig] [pj][ak][SL][dz][fx][gc][fx][dz][dz]\n[dz][dz][sl][PJ][pj][oh][PJ][pj][pj]\n[oh] [ig] [pj] [oh] [yd] [ig] [pj][pj]\n[oh][ig][ig] [pj][ak][SL][dz][fx][gc][fx][dz][dz]\n[dz][dz][sl][PJ][pj][oh][PJ][pj][pj]\n[oh] [ig] [pj] [oh] [yd] [ig] [pj][pj]\n[pj] [pj] [dz][fx][gc][fx][dz][fx] [dz][sl][dz][pj]\n[pj] [pj] [dz][fx][gc][fx][dz][fx] [sl] [PJ] [fx] [gc] [sl] [PJ] [pj]\n[oh][ig][ig] [pj][ak][SL][dz][fx][gc][fx][dz][dz]\n[dz][dz][sl][PJ][pj][oh][PJ][pj][pj]\n[oh] [ig] [pj] [oh] [yd] [ig] [pj][pj]\n[pj] [pj] [dz][fx][gc][fx][dz][fx] [dz][sl][dz][pj]\n[pj] [pj] [dz][fx][gc][fx][dz][fx] [sl] [PJ] [fx] [gc] [oh] [ig][ig]\n[pj][ak][SL][dz][fx][gc][fx][dz][dz]\n[dz][dz][sl][PJ][pj][oh][PJ][pj][pj]\n[oh] [ig] [pj] [oh] [yd] [ig] [pj][pj]\n[ig] [pj] [oh] [yd] [ig] [pj][pj]"],
    ["The Legend of Zelda - Lost Woods", "g j k\ng j k\ng j k x z\nk l k h f\nd f h f\ng j k\ng j k\ng j k x z\nk l x k h\nk h d f\ny u i | o p a | s a u\ny u i | o p a | s d f\ny u i | o p a | s a u\ng f j h k j l k z l x z c k k l j k"],
    ["The Legend of Zelda - Saria's Song", "i p a i p a i p a f d\nasaou yuou\ni p a i p a i p a f d\nasfao aoyu\nyui opa sau\nyui opa sdf\nyui opa sau u\ny i u o i p o a p s a d s fgdf"],
    ["The Legend of Zelda - Song of Stormso", "9 [ip] [ip] 0 u [oa] | q [ps] [ps] 0 u [oa]\n9 [ip] [ip] 0 u [oa] | q [ps] [ps] 0 u [oa]\n[9d] g [ipz] [ip] | [9d] g [oaz]\n[qx]cxcxl [aoj]\nj [ipd] g h [jq] [ip]\nj [ipd] g h [ef] [up]\n9 [ip] [ip] 0 u [oa] | q [ps] [ps] 0 u [oa]\n9 [ip] [ip] 0 u [oa] | q [ps] [ps] 0 u [oa]\n[9d] g [ipz] [ip] | [9d] g [oaz]\n[qx]cxcxl [aoj]\nj [ipd] g h [jq] [ip]\nj [ipd] g h [jq] [dz]\n9 [ip] [ip] 0 u [oa] | q [ps] [ps] 0 u [oa]\n9 [ip] [ip] 0 u [oa] | q [ps] [ps] 0 u [oa]"],
    ["The Legend of Zelda - Song of Stormso (v. 2)", "9ey 9Ey uiuiute\ne9qwe e9qw0\n9ey 9Ey uiuiute\ne9qwe e9"],
    ["The National Anthem of USA", "o u t u o s f d s u I o o o f d s a p a s s o u t\no u t u o s f d s u I\no o o f d s a p a s s o u t\nf f f g h h g f d f g g\ng f d s a p a s s u t\no s s s a p p p d g f d s a\no o s d f g h s d f g d s s"],
    ["The Salut d'Amour", "xhjxzlklc c c\nzxhjxzlklz z z\nZxhjxzlklb b b vcx zlj k l"],
    ["The Walking Dead - Reconciliationo", "[WO] D s O D s O D O D s [wo] D s o D s o D s o D s [WD] Y t s Y t\n[WO] Y t [WP] g D w Y t w Y t w Y t w Y t [WD] Y t s Y t\n[OW] Y t s [YP] [to] w Y t w Y t w Y t w Y t\n[4WO] Y t s Y t [WD] Y t s [YD] D [wg] Y t w Y t w Y t w s P\n[WO] Y t s Y t [WD] Y t W Y D [WP] Y t W Y t [WD] Y t [WDZ] Y t [sl] Y t\n[WOH] Y t [WPJ] [gc] [DZ] w Y t w Y t w Y t w Y t [WDZ] Y t [sl] Y t\n[WOH] Y t [sl] [YPJ] [toh] w Y t w Y t w Y t w Y t\n[WOH] Y t [sl] Y t [WDZ] Y t W Y [stl] [WDZ] [vh] [ZD] w Y t w Y t w Y t w Y t\n[WOH] Y t [sl] Y t [WDZ] Y t W Y t W [sl] [PJ] w Y t w Y t w Y t w Y t\n[WO] D s O D s O D s O D s\n[5wo] D s o D s o D s o D s [WOD]"],
    ["The Walking Dead - Themeo", "o d j J j d o d j J j d o d j J o d j J j d\no d j J j d o d j J o d j J j d o d j J j d\no d j J [5w] y p P p y [5w] y p P p y [5w]\ny p P [5w] y p P p y [5w] y p P p y [5w]\ny p P [5w] y p P p y [5w] y p P p y [5w] y p P [30]\nr I o I r [30] r I o I r [30] r I o [30]\nr I o I r [30] r I o I r [30] r I o [30]\nr I o I r [30] r I o I r [30] r I o [18]\nw y Y y w [18] w y Y y w [18] w y Y [18]\nw y Y y w [18] w y Y y w [18] w y Y [18]\nw y Y y w [18] w y Y y w [18] w y Y [5w]\ny p P p y [5w] y p P p y [5w] y p P [5w]\ny p P p y [5w] y p P p y [5w] y p P [5w]\ny p P p y [5w] y p P p y [5w] y p P[5w]"],
    ["Titanic - My heart will go on!", "[t8] ttt [r5] t r [r4] t y [u5] y\n[t8] ttt [r5] t t [w4] 5\n[t8] ttt [r5] t r [r4] t y [u5] y\n[t8] ttt [r5] t y [w4] [w5]\n[t8] t [y5] w [o4] i u [y5]\nu i [u8] y t [r5] t r [e4] [w5]\n[t8] t [y5] w [o4] i u [y5]\nu i [u8] y t [r5] t r [r4] t y [u5] y [t8]"],
    ["Transformers - Arrival to Eartho", "w t # w t Y E y q E t W\nt w t o Y i Y y t W\nt w t Y E y q E t W #t #E # Y y t\nw E t #E #y Y t # y Y i o s\no s D P # d # i P s O\ns o s h # D # g D d s O # s P D d s\no P s # P d D s\nt w Y # y E i # Y E I\ni T O # I T p # O u a # O u S\np u S # O u S # a # #G\nd p d g s f o s d P\nd p d j g h g f d P\nd p d g s f o s d P\nd s g f d # p s d # s f g d\n[YP] [ips] [do] # [to] [yip] [YP] # [YP] [dp] [sip]\n[YP] p o # [si] P p # [YP] p o [ye]\nw w w w w\n9 w E t y [et]\nq e t y Y E # ( e w q w\n9 w E # q 9 E t # E e w # q w e\ny o o # i o y # t E E # t y y # I # p\no # i o p #P p y o o p [yo] [yo] [eyp] # [wo]"],
    ["Transformers - Optimus", "0 w e Etyt | q e E tyYE\nw ewqw | 0 w E yYit\nq e E tyYE | P poio\nq y | ty | q E | eE\nw y | ti | w t EE e\no d | sd | o P | pP\no d | sg | o s PP p\nu o p Psds | i p P sdDP\no poio | u o P dDgs\ni p P sdDP | J jhgh\ni d | sd | i P | pP\no d | sg | o s PP p\nh z | lz | h J | jJ\nh z | lc | h l JJ j\nh d h J g\nj s g h D\nh | h g Jjh\nd g h | h g | j J h"],
    ["Transformers - Scorponok Theme", "Q Q e Q Q e Q e\ne e T e e T e T\nT T u T T u T u\nr r Y r r Y e W\nQ Q e Q Q e Q e\ne e T e e T e T\nT T u T T u T u\nr r Y r r Y e W"],
    ["Transformers", "we Ety [te] qe tyY E [(] ewqw\n9wE | q9Et Eew q w e\nyoo ioy tEE tyy Ip\no iop Ppyoop [wyo] [yo] [9ey]"],
    ["Transformers (v. 2)", "op Psd [sp] ip sdD P Y poio\nyoP | iyPs Ppo i o p\ndhh ghd sPP sdd Gj\nh ghj Jjdhhj [odh] [dh] [ypd]"],
    ["Transformers (v. 3)", "[wo] [ep] [EP] [ts] [yd] [tsep]\n[qi] [ep] [ts] [yd] [YD] [EP]\n[Y9] [ep] [wo] [qi] [wo]\n[9y] [wo] [EP] | [qi] [9y] [EP] [ts]\n[EP] [ep] [wo] [qi] [wo] [ep]\n[yd] [oh] [oh] [ig] [oh] [yd] [ts] [EP] [EP] [ts] [yd] [yd] [IG] [pj]\n[oh] [ig] [oh] [pj] [PJ] [pj] [yd] [oh] [oh] [pj] [woydh] [ydoh] [9yepd]"],
    ["Tupac - Changeso", "pa [sup] s [auo] po [yp] a [tou] u ytr y o e y o p o [wtyu]\ntyuosdfhlzxvm [sup] [auo] [yp] o [tuo] u [wy] uyr y o [eyu] y [wy]\npa [sup] [auo] [yp] o [tuo] u [wy] uyr y o [eyu] y [wy]\n[sup] [auo] [yp] o [tuo] u [wy] uyr y o [eyu] y [wy]\npa [sup] [auo] [yp] o [tuo] u [wy] uyr y o [eyu] y [wy]\nCHORUS ero weu 90w\nTranscribed and Submitted by Brian"],
    ["Twilight - Bella Reborno", "[260] | [269] | [260] | [269] |\nJ j [269h] f d h J [260j]\n[29] q w 2 w E e [2q] e w 2 0 w q\n[29y] [qi] [wo] [29] [wo] [EP] [ep] [29ep] [qi] [wo] [29] [wo] [0u] [9y]\nJ [29j] q h [wg] 2 f [wh] E d [es] p\n[29] q e p [wo] 2 i [0p] w u [qy]\nJ [29j] q h [wgz] 2 f [whj] E [fh] [edj]\nJ [2j] q p [wod] [uf] [wig] 0 E [0yd]\n[PJ] [29pj] q h [18dg] f [5ph] 6 [pf] [id] g [6pf]\n4 d [5o] [of] [6pg] 4 [uf]\n[29yd] [6fx] o p d g [4sj] h g [8tps] f\n[29sg] f d [4qip] [is] [5wi] p o p"],
    ["Twilight - Bella's Lullaby", "IyIy oyoy IyIy uyuy IyIy oyoPpyp yoyoy\nIyIy oyoy IyIy uyuy IyIy oyoPpyp yoyoy\nJhfd jhfd jhfd jhfd\nJjhfdhap as op Jjhfdhap as Gh Df hjkhjkCk hGf Ck hjk Cxz\nlkj hGd a Jjhfdhap aassGGjk hjkddssaapuuooaaddddssaap\nuuooaaddGddssaapuuoossjj jjhffdap ffhhkkzz bbvxxzkj hjkddssaap\nuuooaaffGddssaapuuooaaffGddssaapoossffjjk\nj H j H j f j d\np S j H j H j f j d\nJjhfdhap as op Jjhfdhap\nas Gh kz cvbn m nbvCn\nCkhGf Ckhjk Cxzlkj G\nJjhfdhap aa ss GG jk\nhjk dd ss aa p uu oo aa dd\ndd ss aa p uu oo aa dd G dd ss aa p uu oo aa ff G\ndd ss aa p oo ss ff jj jj h ff dap ff hh kk zz\nbb v xx zkj\nhjk dd ss aa p uu oo aa ff G dd ss aa p uu oo aa ff G\ndd ss aa p oo ss ff jj k"],
    ["Twilight - Kiss The Rain", "Y O P P s s | O P s P D D | D g h h H H | J l J H h | H h D D g g | D S S D D | O P s s S | D S s P\nY O P P s s | O P s P D D | D g h h H H | J l J H h | H h D D g g | D S S D D | O P s s S i O o O\nW t Y i w W i Y | w W Y Y T T t t E E W E t t | W t Y i w o o i Y | t T Y E e T t T Y i o O s P\nY O P P s s | O P s P D D | D g h h H H | J l J H h | H h D D g g | D S S D D | O P s s S | D S s P\nY O P P s s | O P s P D D | D g h h H H | J l J H h | H h D D g g | D S S D D | O P s S | i O o [p3] [e y p] Q e O\nO f j k a S S | p a S a f f | f G H H j j | k L k j H | p O u u I I | u y y u u | e r t T y | Q e W e | a S S d | I p O p e"],
    ["Twilight - Kiss The Rain (v. 2)", "D H J J l l | H J l J Z Z | Z c v v V V | B m B V v | V v Z Z c c | Z L L Z Z | H J l l L | Z L l J\nD H J J l l | H J l J Z Z | Z c v v V V | B m B V v | V v Z Z c c | Z L L Z Z | H J l l L g H h H\nO s D g o O g D | o O D D S S s s P P O P s s | O s D g o h h g D | s S D P p S s S D g h H l J\nD H J J l l | H J l J Z Z | Z c v v V V | B m B V v | V v Z Z c c | Z L L Z Z | H J l l L | Z L l J\nD H J J l l | H J l J Z Z | Z c v v V V | B m B V v | V v Z Z c c | Z L L Z Z | H J l L | g H h [j0] [p d j] I p H\nH x b n k L L | j k L k x x | x C V V b b | n m n b V | j H f f G G | f d d f f | p a s S d | I p O p | k L L z | G j H j p"],
    ["Twilight - New Moon The Meadow", "[etu] l k j [eti] l k j [wry] k j h H f x c x\n[etu] l k j [eti] l k j [wry] k j h H f x c x\nf p s f g p s g d o a d o\nfpsf gpsg doad oaf psfdf\nfpsf gpsg\ndoadaoa fpsfgf\nffg pspspsph oaoaoaof pspspspsffj pspspsph\noaoaoaof pspspspsffh OsOsOsOg OsOsOsOf\nososososff lkkjhggfd dsdf\nfpsf gpsg doad oaf psfdf jkh gf\nfpsf gpsg doadaoa\nfpsfgf pasg sddsd fss fjhgkjh sddsapppdds dddhhj\nlkj lkj kjh H fxzx\nlkj lkj kjh H fxcx\nfpsf gpsg doad oaf psfdf jkh gf\nxjlx cjlc zhkzkhk xH l xc"],
    ["Twinkle Twinkle Little Star", "tt oo pp o\nii uu yy t\noo ii uu y\noo ii uu y\ntt oo pp o\nii uu yy t"],
    ["Twinkle Twinkle Little Star (v. 2)", "[ts][ts] [oh][oh] [pj][pj] [oh]\n[ig][ig] [uf][uf] [yd][yd] [ts]\n[oh][oh] [ig][ig] [uf][uf] [yd]\n[oh][oh] [ig][ig] [uf][uf] [yd]\n[ts][ts] [oh][oh] [pj][pj] [oh]\n[ig][ig] [uf][uf] [yd][yd] [ts]"],
    ["Tears - The daydream", "\ni P s [6S] q E t T \ni [I9] E Y i I  P [O5] \n9 W P s O s G [g8] \nW T [siO] H [J4] 8 Q E \nT [iJ]l L Z [6L]q E \nt T g [PGD9] E Y I P \nS D G [qg] t i o p \n4 8 q e t i [6S] q \nE t T i [I9] E Y i \nI P [O5] 9 [WP][tsY] O [tsY] \nG [g8] W T [siO] i H [J4] \n8 Q E T J l L Z \n[6L] q E t T [gSP] [GD9] Q \nE Y [gI] D [SQ] s [qg] t \ni p g g [PGD9] E Y I \nP P g G J [l6] q J \nE g [TS] s P i T g \n[G9] Q E Y I D [SP] s \n[qg] t [ti] o p [pg] 4 8 \nq e t Y i i [6S] q \nE t T i [I9] E Y i \nI [POI] [O5] 9 W [WO] P [tsW] \nO [ts] W G [g8] W T [si] \nO s W H [J4] 8 Q W \nEJ l L Z [6L] qE \ntT g[PGD9] QE[YD][qS] \ne t s[6P]q E t T \nJl[L4]8 Q E T [iJ] \ng l g [6L]q E t T \n[iJ] g l g[L9]g [lE]g \n[YJ] g [JI] g[lP] g  L g\n[ZP]g L g [c6] q E t \nT[gSPE][PGD9]  Q E Y I G \ng [PG] H J l [6L] q J \nE g TS P iT g \n[PGD9]Q E Y [gYPID] D[YSI] s \n[sqpg] t i p  g 4 8 q \ne t Y [tI] q i [6S] q \nE t T i [I9] E       Y       i \nI E P [O5]9 W [tO]P \n[tsW] O[tsW] G [g8] W  T [sigO] \nW H [J4] 8 Q [iTQE] J l \nL Z [6L] q E[gTJ] gS \nP i [sq] e t [iD] G  g \n[iG] g [6J]q E t T J \ng l g [L4] 8  Q W E \nJ g l g [6L]q E t \nT [iJ] g lg [L9] g[lE] \ng [YJ] g [IH] g[PJ] g l \ng [PL] gl g[6J]q E \nt T Jg l g [L4]8 \nQ E T i J g l g \n[6L] q  E tT [iJ] g l \ng [L9] g [lE] g [YJ] g [IH] \ng [PJ] g l g    [PL] g l \ng [6J]q E t T i [6S] \nq E t T S i I O \n[I9] E Y i I[POI][O5] 9 \nW [tO]P[tsY]  O [sI] G [g8] \nW T [siO] H [J4] 8 Q [iTQE] \nJl L Z [6L]q E [TJ] \ng S P i[qp] e t [si] \nD G [ig] j[g6SJ] q E T \ni P S g [gSPJ]  "],
    ["Written In The Stars", "[6u] u y y u [i4] t i u y t [t8] t u y y t [y5] u y\n[t6] u y y u [i4] t p o i [u1] t u u o o t a t\na [p6] y y y t 4 y y y t 1 y y y t 5 y y y t"],
    ["X-Files", "6 8 0 q 8 0 q 8 0 q [680] [680]\np f d f h f\np f d f j f\nl k j h j f\nl k j h k\np f d f h f\np u"],
    ["Yiruma - Kiss The Rain (original)", "o s d [8d] [wf] [tf] w u\ns d f [5d] [9h] [wh] 9 r\nh j k [6k] [0l] [el] 0 t\nz z-x z l [0k] r u r o\nl k h [qh] [tj] [ij] t p\nh g [0g] [rh] [uh] r o\ns d [9f] [eg] [yg] e i\n[yh] [eg] [5f] 9 w 9 rd\n[9o] [ws] [8d] [1d] [5f] [8f] 5 0 5 8\ns [5d] f [5d] [9h] [wh] 9 r 9 w\nh [9j] k [6k] [0l] [el] 0 t\n[0z] z-[ex] z [0l] [3k] 7 0 7 w\n[7l] [0k] [7h] [4h] [8j] [qj] 8 e 8 q\n[8h] g [3g] [7h] [0h] 7 w 7 0\n[7s] d [9f] [eg] [yg] [ep] [is] e [ua] [8s] w t i u\ns f h [qj] t i t q\n[ta] [is] [tj] [3h] 7 0 7 6\n[0a] [ws] [0h] [9h] [eg] [yg] [ef] [5f] [9d] [wd] [9s] [8d] [wf] [tf] w u\n[ws] [tf] [wh] [qj] t i t 7\nk [rk] j [3h] 7 0 [7f]-g-[6h]\n0 [eg] [0f] [9g] e [yh] e [ij] e k y [el] [5x] 9 w 9 [rz]\n[9o] [ws] [9d] [1d] [5f] [8f] 5 0 5 8\ns [5d] f [5d] [9h] [wh] 9 r 9 w\nh [9j] k [6k] [0l] [el] 0 t\n[0z] z-[ex] z [0l] [3k] 7 0 7 w\n[7l] [0k] [7h] [4h] [8j] [qj] 8 e 8 q\n[8h] g [3g] [7h] [0h] 7 w 7 0\ns d [9f] [eg] [yg] e i e [yh] rg [5f] 9 w 9 [rd]\n[9o] [ws] [9d] [td] [of] [sf] o d\ns d f [wd] [yh] [oh] y a\nh j k [ek] [ul] [pl] s\nz z-x z l [0k] t i o\nl k h [qh] [tj] [ij] t p\nh g [0g] [rh] [uh] r o\ns d [9f] [eg] [yg] e [id] s a\n[1s] 5 8 0 w t u o [1to]\n"],
    ["Yiruma - Kiss The Raino", "w t y [8y] u u | w t w t y u [5y] o o | w r w o p a [6a] s [tus]\nt u d d f d s [3a] w r w 3\ns a o [qo] p p | t i t o i [3i] o o\nw r w t y [9u] i [eyi] e y e o i [5u] w r i u [5y]\nw t y [8y] u u | w t w t y u [5y] o o | w r w o p a [6a] s [tus]\nt u d d f d s [3a] w r w 3\ns a o [qo] p p | t i t o i [3i] o o\nw r w t y [9u] i [eyi] e [5t] 9 r\n[8t] 0 w t u t u o [qp] t i t q r t p [3o]\nw r w 6 r t o [9o] i [wyi] u [5u] y\nw t y [8y] u u | w 8 t u o [qp] t u t 7 a a p [3o] w r u i [6o] i u\n[9i] w o w [9p] t a s [wf] y o y [wd]\nw t y [8y] u u | w t w t y u [5y] o o | w r w o p a [6a] s [tus]\nt u d d f d s [3a] w r w 3\ns a s a o [qo] p p | t i t p o i [3i] o o\nw r w t y [9u] i [eyi] e [5t] 9 r [8t] 0 w t u\ns a o [qo] p p | t i o i [3i] o o | w r t y [9u] i i\ne [5t] 9 r [8t] 0 w t 8 0 w t u"],
    ["Yiruma - Kiss The Raino (v. 2)", "o s d [td] f f | o s o s d f [wd] h h | o a o h j k [ek] l [sfl]\ns f z z x z l [0k] o a o 0\nl k h [ih] j j | s g s h g [0g] h h\no a o s d [yf] g [pdg] p d p h g [wf] o a g f [wd]\no s d [td] f f | o s o s d f [wd] h h | o a o h j k [ek] l [sfl]\ns f z z x z l [0k] o a o 0\nl k h [ih] j j | s g s h g [0g] h h\no a o s d [yf] g [pdg] p [ws] y a\n[ts] u o s f s f h [ij] s g s i a s j [0h]\no a o e a s h [yh] g [odg] f [wf] d\no s d [td] f f | o t s f h [ij] s f s r k k j [0h] o a f g [eh] g f\n[yg] o h o [yj] s k l [ox] d h d [oz]\no s d [td] f f | o s o s d f [wd] h h | o a o h j k [ek] l [sfl]\ns f z z x z l [0k] o a o 0\nl k l k h [ih] j j | s g s j h g [0g] h h\no a o s d [yf] g [pdg] p [ws] y a [ts] u o s f\nl k h [ih] j j | s g h g [0g] h h | o a s d [yf] g g\np [ws] y a [ts] u o s t u o s f"],
    ["Yiruma - River Flows in Youo", "[el] k l k [il] h l g | s f\n[el] k l k [il] h l g |\ns f [el] s k l s f k l i o h l o h g o [tf] o g o h o f [wd] y o p d s a\n[es] u o s f u s d [qf] i p d f i f g [th] o s d h o g f [wd] y o p d\n[e s f l] s k l s f k l i o h l o h g [tf] o g o h x [oz] [z x] l k\n[el] s f l s d [qf] i p d f i f g [th] o s d h o g f [wd] y o p\ns d s a [es] u o u\ns d s a [qs] i o i\ns d s a [ts] d f g h f d s [wa] y o p\ns d s a [es] u o u\ns d s a [qs] i o i\ns d s a [ts] d f g h f d s [wa] y o p\ns s d s a [es] u o u\ns s d s a [qs] i o i\ns s d s a [ts] d f g h f d s [wa] y o p\ns s d s a [es] u o u\ns s d s a [qs] i o i\ns d s a [ts] d f g h f d s [wa] y o p a\ns f h [e s f l] k l k [il] h l g [tf] g h f [wd] y o s a\n[es] u o s d [qf] i p f g [th] o s g f [wd] y o p\n[e s f l] k l k [il] h l g [tf] g h x [oz] o s a\n[es] u o s d [qf] i p f g [th] o s g f [wd] y o p [e u o s]"],
    ["Yiruma - When Love Falls", "[s8] w Y s [d9] [iw] [oy] a [D(] [ot] s h [h0] P [su] P H O [hi] q g D Y o [st] s [i9] [OW] s [s5] t i a\n[s8] [ow] h g h [ir] [oy] o s Y h g [h0] P [su] h H O [hi] g D Y o s D g [d5] i [sr] a\n[s8] [ow] h g [h0] r y i s [st] Y h g [h0] t P h [gq] t [hi] g D Y o [s8] [D%] s g [h5] 9 r w\nl W Y O l D [kw] [ly] [zr] Z o t [zo]\n[ls] H J l h g D [dw] h [ai] D d s o P o\n[ls] W Y O [ls] D [kw] [ly] [zr] Z o t [zo]\n[ld] H J l h g D h [dw] [ly] k [li] o\n[s8] [ow] h g [h0] r y o s [st] Y h g [h0] t o h [gq] t [hi] [gq] D Y o\n[s8] [D%] g [d5] 9 [aw] [o5] s\n[s8] o h g [h0] r y o s [ot] Y h g [h0] t u P h [gq] t [hi] q g D Y o [st] D s g [h5] 9 [rw] [y5]\n[ls] W Y O [ls] D [k5]\n[ls] [zd] Z z [lt] H J l [h4] g D [dq] h a D [dw] [sy] w ^ w E ^\n[ls] W Y O [ls] D [k5] [ls] [zd] Z z\n[lt] H J l [hs4] g D h [dq] l [kq] l D 8 w t Y o s D\n[ls] [ls] [kw] [ly] [zr] Z o t [zo]\n[ls] H J l [hw] g D [dw] h [ai] D [do] s o P o\n[ls] W Y O [ls] D [kw] [ly] [zr] Z o t [zo]\n[ld] H J l [hw] g D [hw] l k l t o d D P D\n[ls] W Y O [ls] D [kw] [ly] [zr] Z o t [zo]\n[ls] H J l [hw] g D [dw] h [ai] D [do] s o P o\nl W Y O l D [kw] [ly] [zr] Z o t [zo]\n[ls] H J l [hw] g D [hw] [ly] [ki] [lhot]"],
    ["Zeki Muren - Yildizlarin Altindao", "jhgfdsdf | gfdsa [f d s a]\ndsasd f | s | asdsfsap [a s d f g h j]\njhgfdsdf | gfdsa [f d s a]\ndsasd f | s | asdsfsap [p o i u y t r e]\npasds g | f fggfdsf [p a s d f]\ndsasd f | s | asdsfsap [p o i u y t r e]\npasds g | f fggfdsf [p a s d f]\ndsasd f | s | asdsfsap [a s d f g h j]\n[j j] hgf | h ggf | ggffdds | f dsas\ndsasd f | s | asdsfsap"],
    ["One Punch Man - sad theme", "u  o  d  [6s] | a s 4 | d  [8f] | s  g  [5d] | o p a  s \n[6s] | 0 e t a s 4 8 q [eo] s d f g [8s] w t u h |\n[5d] 9 w [ro] f g d f [6s] 0 e t s   d   f [4f] 8 [ql] e k   l   [8h] w t u |\n[5j] [9f] [ws] [rp] [5wa] s d H j [ufk]   [oh]   [dz]   [6sfl] 0 e t 6 0 [eak] [tsfl] \n4 8 q e 4 8 [qdz] e [8fhx] w t [usfl] 8 w [tgc] u\n[5fx] 9 [dz] w [rsfl] 5 [9ak] [wdz] r [6sfl] 0 e t 6 [0oh] [edz] t \n[4sfl] 8 q e 4 8 [qdz] e [8fhx] w t [usl] 8 w [tgc] u \n[5dgz] 9 w r [5sl] 9 [wak] r [6sl] 0 [ak] e [tsl] [6sl] 0 [ak] e [tsl]\n[4sl] 8 [ak] q [esl] [4sl] 8 [qdz] e [8fx] w [dz] t [ufx] [8fx] w [dz] t [ufx] \n[5ak] 9 [wgc] r [5fx] 9 [wdz] r [6sfl] k j k l | l   c \n[4fhx] | l [hkv] 8 | 5   v c x l h g f s o i u t [18]\n"],
    ["Naruto - SILHOUETTE kana boon", "rery erery er yey ueu IoIuy hGd hGd hGdfGS\nIyuI yIIuyupp uTuy apy apy yTyuy\nIyuuI yyIuyupp uTuy apy apy yTyuy\nrIIy yIIy yapIy rTyy Tyu uuIuyu\nyeyIIuyu ueTuoIuIuy apy apy yTyuI\nyeyIIuyu ueTuoIIIuy apy apy yTpuI\nyeyIIuyu ueTuuopIuy apy apy yTyuy\npIuu pIuu aIuy rTyy Iuyr rery erery ereyu\n ]"],
    ["Naruto - Blue Bird", "SGHj HG SGHjkjkLL\nSGHj HG GLk GLk ffGG\nSGHjSjHG fGpaS SSdfGfffff GHjHHH\nSGHjSjHG fGpaS SSdjHGfGG\nSGHjjjjjH HHjkkkjHG\nGjkLkLkL xzL ]"],
];
