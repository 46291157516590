"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
const ng = window.angular;
const rxjs_1 = require("rxjs");
const operators_1 = require("rxjs/operators");
//@ts-ignore
const songs_1 = require("./songs");
const editHistory_1 = require("@games/piano/src/editHistory");
//@ts-ignore
const soundsLoader = require.context('./sounds', true, /\.mp3$/, 'sync');
const soundCache = {};
function importAll(r) {
    //@ts-ignore
    r.keys().forEach((key) => (soundCache[key] = r(key).default));
}
importAll(soundsLoader);
const defaultData = {
    size: '4',
    label: 'keys',
};
const defaultTemp = 70;
class PianoCtrl {
    constructor($scope, $filter, $timeout, ConfigService, ModalServiceFactory, SoundService) {
        this.$scope = $scope;
        this.$filter = $filter;
        this.$timeout = $timeout;
        this.ConfigService = ConfigService;
        this.ModalServiceFactory = ModalServiceFactory;
        this.SoundService = SoundService;
        this.whiteKeys = [
            { code: 'Digit1', shiftKey: false, title1: '1', title2: 'C2', src: "./C2.mp3" },
            { code: 'Digit2', shiftKey: false, title1: '2', title2: 'D2', src: "./D2.mp3" },
            { code: 'Digit3', shiftKey: false, title1: '3', title2: 'E2', src: "./E2.mp3" },
            { code: 'Digit4', shiftKey: false, title1: '4', title2: 'F2', src: "./F2.mp3" },
            { code: 'Digit5', shiftKey: false, title1: '5', title2: 'G2', src: "./G2.mp3" },
            { code: 'Digit6', shiftKey: false, title1: '6', title2: 'A2', src: "./A2.mp3" },
            { code: 'Digit7', shiftKey: false, title1: '7', title2: 'B2', src: "./B2.mp3" },
            { code: 'Digit8', shiftKey: false, title1: '8', title2: 'C3', src: "./C3.mp3" },
            { code: 'Digit9', shiftKey: false, title1: '9', title2: 'D3', src: "./D3.mp3" },
            { code: 'Digit0', shiftKey: false, title1: '0', title2: 'E3', src: "./E3.mp3" },
            { code: 'KeyQ', shiftKey: false, title1: 'q', title2: 'F3', src: "./F3.mp3" },
            { code: 'KeyW', shiftKey: false, title1: 'w', title2: 'G3', src: "./G3.mp3" },
            { code: 'KeyE', shiftKey: false, title1: 'e', title2: 'A3', src: "./A3.mp3" },
            { code: 'KeyR', shiftKey: false, title1: 'r', title2: 'B3', src: "./B3.mp3" },
            { code: 'KeyT', shiftKey: false, title1: 't', title2: 'C4', src: "./C4.mp3" },
            { code: 'KeyY', shiftKey: false, title1: 'y', title2: 'D4', src: "./D4.mp3" },
            { code: 'KeyU', shiftKey: false, title1: 'u', title2: 'E4', src: "./E4.mp3" },
            { code: 'KeyI', shiftKey: false, title1: 'i', title2: 'F4', src: "./F4.mp3" },
            { code: 'KeyO', shiftKey: false, title1: 'o', title2: 'G4', src: "./G4.mp3" },
            { code: 'KeyP', shiftKey: false, title1: 'p', title2: 'A4', src: "./A4.mp3" },
            { code: 'KeyA', shiftKey: false, title1: 'a', title2: 'B4', src: "./B4.mp3" },
            { code: 'KeyS', shiftKey: false, title1: 's', title2: 'C5', src: "./C5.mp3" },
            { code: 'KeyD', shiftKey: false, title1: 'd', title2: 'D5', src: "./D5.mp3" },
            { code: 'KeyF', shiftKey: false, title1: 'f', title2: 'E5', src: "./E5.mp3" },
            { code: 'KeyG', shiftKey: false, title1: 'g', title2: 'F5', src: "./F5.mp3" },
            { code: 'KeyH', shiftKey: false, title1: 'h', title2: 'G5', src: "./G5.mp3" },
            { code: 'KeyJ', shiftKey: false, title1: 'j', title2: 'A5', src: "./A5.mp3" },
            { code: 'KeyK', shiftKey: false, title1: 'k', title2: 'B5', src: "./B5.mp3" },
            { code: 'KeyL', shiftKey: false, title1: 'l', title2: 'C6', src: "./C6.mp3" },
            { code: 'KeyZ', shiftKey: false, title1: 'z', title2: 'D6', src: "./D6.mp3" },
            { code: 'KeyX', shiftKey: false, title1: 'x', title2: 'E6', src: "./E6.mp3" },
            { code: 'KeyC', shiftKey: false, title1: 'c', title2: 'F6', src: "./F6.mp3" },
            { code: 'KeyV', shiftKey: false, title1: 'v', title2: 'G6', src: "./G6.mp3" },
            { code: 'KeyB', shiftKey: false, title1: 'b', title2: 'A6', src: "./A6.mp3" },
            { code: 'KeyN', shiftKey: false, title1: 'n', title2: 'B6', src: "./B6.mp3" },
            { code: 'KeyM', shiftKey: false, title1: 'm', title2: 'C7', src: "./C7.mp3" },
        ];
        this.blackKeys = [
            { code: 'Digit1', shiftKey: true, title1: '!', title2: 'C#', title3: '2', src: "./Cs2.mp3" },
            { code: 'Digit2', shiftKey: true, title1: '@', title2: 'D#', title3: '2', src: "./Ds2.mp3" },
            null,
            { code: 'Digit4', shiftKey: true, title1: '$', title2: 'F#', title3: '2', src: "./Fs2.mp3" },
            { code: 'Digit5', shiftKey: true, title1: '%', title2: 'G#', title3: '2', src: "./Gs2.mp3" },
            { code: 'Digit6', shiftKey: true, title1: '^', title2: 'A#', title3: '2', src: "./As2.mp3" },
            null,
            { code: 'Digit8', shiftKey: true, title1: '*', title2: 'C#', title3: '3', src: "./Cs3.mp3" },
            { code: 'Digit9', shiftKey: true, title1: '(', title2: 'D#', title3: '3', src: "./Ds3.mp3" },
            null,
            { code: 'KeyQ', shiftKey: true, title1: 'Q', title2: 'F#', title3: '3', src: "./Fs3.mp3" },
            { code: 'KeyW', shiftKey: true, title1: 'W', title2: 'G#', title3: '3', src: "./Gs3.mp3" },
            { code: 'KeyE', shiftKey: true, title1: 'E', title2: 'A#', title3: '3', src: "./As3.mp3" },
            null,
            { code: 'KeyT', shiftKey: true, title1: 'T', title2: 'C#', title3: '4', src: "./Cs4.mp3" },
            { code: 'KeyY', shiftKey: true, title1: 'Y', title2: 'D#', title3: '4', src: "./Ds4.mp3" },
            null,
            { code: 'KeyI', shiftKey: true, title1: 'I', title2: 'F#', title3: '4', src: "./Fs4.mp3" },
            { code: 'KeyO', shiftKey: true, title1: 'O', title2: 'G#', title3: '4', src: "./Gs4.mp3" },
            { code: 'KeyP', shiftKey: true, title1: 'P', title2: 'A#', title3: '4', src: "./As4.mp3" },
            null,
            { code: 'KeyS', shiftKey: true, title1: 'S', title2: 'C#', title3: '5', src: "./Cs5.mp3" },
            { code: 'KeyD', shiftKey: true, title1: 'D', title2: 'D#', title3: '5', src: "./Ds5.mp3" },
            null,
            { code: 'KeyG', shiftKey: true, title1: 'G', title2: 'F#', title3: '5', src: "./Fs5.mp3" },
            { code: 'KeyH', shiftKey: true, title1: 'H', title2: 'G#', title3: '5', src: "./Gs5.mp3" },
            { code: 'KeyJ', shiftKey: true, title1: 'J', title2: 'A#', title3: '5', src: "./As5.mp3" },
            null,
            { code: 'KeyL', shiftKey: true, title1: 'L', title2: 'C#', title3: '6', src: "./Cs6.mp3" },
            { code: 'KeyZ', shiftKey: true, title1: 'Z', title2: 'D#', title3: '6', src: "./Ds6.mp3" },
            null,
            { code: 'KeyC', shiftKey: true, title1: 'C', title2: 'F#', title3: '6', src: "./Fs6.mp3" },
            { code: 'KeyV', shiftKey: true, title1: 'V', title2: 'G#', title3: '6', src: "./Gs6.mp3" },
            { code: 'KeyB', shiftKey: true, title1: 'B', title2: 'A#', title3: '6', src: "./As6.mp3" },
        ];
        this.existsCharacter = [
            ...this.whiteKeys, ...this.blackKeys
        ].filter((item) => item).map((item) => item.title1);
        this.size = JSON.parse(localStorage.getItem(`${this.constructor.name}_size`) || 'null') || '4';
        this.octaves = {
            '3': {
                wightKeys: 22,
                blackKeys: 21,
                title: this.$filter('translate')('3 octaves'),
            },
            '4': {
                wightKeys: 29,
                blackKeys: 27,
                title: this.$filter('translate')('4 octaves'),
            },
            '5': {
                wightKeys: this.whiteKeys.length,
                blackKeys: this.blackKeys.length,
                title: this.$filter('translate')('5 octaves'),
            },
        };
        this.labels = {
            keys: {
                title: this.$filter('translate')('Keys')
            },
            notes: {
                title: this.$filter('translate')('Notes')
            },
            none: {
                title: this.$filter('translate')('None')
            }
        };
        this.destroy = new rxjs_1.Subject();
        this.play = new rxjs_1.Subject();
        this.historyPlay = new rxjs_1.Subject();
        this.samplePlay = new rxjs_1.Subject();
        this.data = JSON.parse(localStorage.getItem(`${this.constructor.name}_data`) || 'null') || ng.copy(defaultData);
        this.mainTemp = ng.copy(defaultTemp);
        this.history = [];
        //@ts-ignore
        this.historyState = 'stop';
        this.sampleState = 'stop';
        this.historyPause$ = new rxjs_1.BehaviorSubject(false);
        this.samplePause$ = new rxjs_1.BehaviorSubject(false);
        this.disablePiano = false;
        this.samples = songs_1.musicSheet.map((item, index) => {
            return {
                title: item[0],
                characters: item[1],
                show: index < 10,
                temp: item[2],
            };
        });
        this.sample = {
            emptySamples: false,
            term: '',
            value: [],
            title: ''
        };
        this.showDiv = undefined;
        // showDiv?: 'history' | 'samples' | undefined = 'history'
        this.notesRegex = /(\[[^\]]+\]|\s\|\s|\S+)/g;
        this.$scope.$watch('$ctrl.data', (data) => {
            localStorage.setItem(`${this.constructor.name}_data`, JSON.stringify(data));
        }, true);
        // this.selectSample({
        //     characters: musicSheet[0][1],
        //     title: musicSheet[0][0],
        //     temp: musicSheet[0][2],
        // })
        window.addEventListener('keydown', function (e) {
            if (e.keyCode == 32 && e.target == document.body) {
                e.preventDefault();
            }
        });
    }
    delayPlayTimer(char, delay = 175) {
        if (char == '\n') {
            delay = 160;
        }
        if (char == ' ') {
            delay = 70;
        }
        if (char == '|') {
            delay = 110;
        }
        return (0, rxjs_1.timer)(delay).pipe((0, operators_1.mapTo)(char), (0, operators_1.take)(1));
    }
    playString(value, pause$, keyMap, state) {
        return [
            (0, operators_1.concatMap)((i) => {
                const item = value[i];
                let chars = [];
                if (item) {
                    let withDelay = true;
                    if (/\[\S+\]/.test(item.value)) {
                        withDelay = false;
                        chars = item.value.replace(/(\[|\])/g, '').split('');
                    }
                    else {
                        chars = item.value.split('');
                    }
                    return (0, rxjs_1.of)(...chars).pipe((0, operators_1.startWith)(undefined), (0, operators_1.concatMap)((char) => {
                        return pause$.pipe((0, operators_1.tap)((pause) => {
                            if (pause) {
                                this.$timeout(() => {
                                    Object.values(keyMap).forEach((item) => {
                                        item.active = false;
                                    });
                                });
                            }
                        }), (0, operators_1.filter)(pause => !pause), (0, operators_1.take)(1), (0, operators_1.concatMap)(() => {
                            return (withDelay ? this.delayPlayTimer(char) : this.delayPlayTimer(char, 50));
                        }));
                    }), (0, operators_1.pairwise)(), (0, operators_1.map)(([prevChar, char]) => {
                        if (withDelay) {
                            this.$timeout(() => {
                                if ((prevChar != undefined) && keyMap.hasOwnProperty(prevChar)) {
                                    keyMap[prevChar].active = false;
                                }
                            });
                        }
                        let key = null;
                        if (keyMap.hasOwnProperty(char)) {
                            key = keyMap[char];
                            this.SoundService.play(keyMap[char].src);
                            this.$timeout(() => {
                                keyMap[char].active = state == 'play';
                                item.active = true;
                                item.correct = true;
                            });
                            return key;
                        }
                        return key;
                    }), (0, operators_1.toArray)(), (0, operators_1.takeLast)(1), (0, operators_1.delay)(50), (0, operators_1.map)((keys) => {
                        this.$timeout(() => {
                            keys.forEach((key) => {
                                if (key)
                                    key.active = false;
                            });
                        });
                        return {
                            item: item,
                            keys: keys
                        };
                    }));
                }
                else {
                    throw new Error(`No history ${i} found...`);
                }
            }),
            (0, operators_1.pairwise)(),
            (0, operators_1.tap)(([prev, curr]) => {
                if (prev.item) {
                    this.$timeout(() => {
                        prev.item.active = false;
                    });
                }
            }),
            (0, operators_1.catchError)((err) => {
                console.error(err);
                return (0, rxjs_1.of)(null);
            }),
        ];
    }
    initPiano() {
        const keys = [
            ...this.whiteKeys.filter((item, index) => {
                return this.octaves[this.data.size].wightKeys > index;
            }),
            ...this.blackKeys.filter((item, index) => {
                return item && this.octaves[this.data.size].blackKeys > index;
            })
        ];
        const disablePlayFilter = (0, operators_1.filter)((e) => {
            if (this.disablePiano) {
                return false;
            }
            if (e.target.tagName == 'INPUT') {
                if (e.target.name == 'samples_term') {
                    return false;
                }
                if (e.target.name == 'temp') {
                    return false;
                }
            }
            return true;
        });
        keys.forEach((item) => {
            return (0, rxjs_1.merge)((0, rxjs_1.fromEvent)(document, 'keydown').pipe(disablePlayFilter, (0, operators_1.filter)((e) => {
                return (e.code == item.code) && (e.shiftKey == !!item.shiftKey);
            }), (0, operators_1.map)((e) => {
                item.active = true;
                return {
                    key: item,
                    event: e,
                    state: true
                };
            })), (0, rxjs_1.fromEvent)(document, 'keyup').pipe(disablePlayFilter, (0, operators_1.filter)((e) => {
                if (this.disablePiano) {
                    return false;
                }
                return e.code == item.code;
            }), (0, operators_1.map)((e) => {
                item.active = false;
                return {
                    key: item,
                    event: e,
                    state: false
                };
            }))).pipe((0, operators_1.distinctUntilChanged)((prev, cur) => JSON.stringify(prev) === JSON.stringify(cur), (item) => item.state), (0, operators_1.tap)((item) => {
                if (item.state) {
                    this.play.next(item.key);
                }
                this.$scope.$apply();
            }), (0, operators_1.takeUntil)(this.destroy)).subscribe();
        });
    }
    $onInit() {
        this.initPiano();
        let keyMap = {};
        let keys = [...this.whiteKeys, ...this.blackKeys.filter((item) => item)];
        keys.forEach((item) => {
            keyMap[item.title1] = item;
        });
        this.play.pipe((0, operators_1.startWith)(undefined), (0, operators_1.bufferTime)(50), 
        // filter((item: any) => item.keys.length > 0),
        (0, operators_1.filter)((keys) => keys.length > 0), (0, operators_1.map)((keys) => {
            return {
                keys: keys,
                // time: Math.floor(Date.now())
            };
        }), (0, operators_1.pairwise)(), (0, operators_1.map)(([prev, cur]) => {
            let accord = cur.keys.map((key) => {
                this.SoundService.play(key.src);
                return key.title1;
            }).join('');
            if (this.historyState != 'stop') {
                this.historyPause$.next(true);
            }
            if (this.sampleState != 'stop') {
                this.samplePause$.next(true);
            }
            return (accord.length > 1) ? `[${accord}]` : accord;
        }), (0, operators_1.bufferTime)(400), (0, operators_1.tap)((accords) => {
            if (accords.length) {
                accords.join('').replace(/(\[[^\]]+\])/g, ' $1 ').split(' ').forEach((accord) => {
                    if (accord)
                        this.$timeout(() => {
                            this.history.push({
                                active: false,
                                correct: false,
                                value: accord
                            });
                            this.history.push({
                                active: false,
                                correct: false,
                                value: ' '
                            });
                        });
                });
            }
        })).subscribe();
        this.historyPlay.pipe((0, operators_1.switchMap)((state) => {
            if (state == 'play') {
                this.historyState = 'play';
                const p = this.getPausableTimer(this.historyPause$.pipe((0, operators_1.tap)((pause) => {
                    this.historyState = pause ? 'pause' : 'play';
                    if (pause) {
                        keys.forEach((item) => {
                            item.active = false;
                        });
                    }
                })));
                return p.stepTimer.pipe(...this.playString(this.history, this.historyPause$, keyMap, this.historyState), 
                // take(this.sample.value.length - 1),
                (0, operators_1.finalize)(() => {
                    this.$timeout(() => {
                        this.historyState = 'stop';
                        this.history.forEach((item) => {
                            item.correct = false;
                            item.active = false;
                        });
                        keys.map((item) => {
                            item.active = false;
                        });
                    });
                }));
            }
            this.historyState = 'stop';
            return rxjs_1.NEVER;
        })).subscribe();
        this.samplePlay.pipe((0, operators_1.switchMap)((state) => {
            if (state == 'play') {
                this.sampleState = 'play';
                const p = this.getPausableTimer(this.samplePause$.pipe((0, operators_1.tap)((pause) => {
                    this.sampleState = pause ? 'pause' : 'play';
                    if (pause) {
                        this.$timeout(() => {
                            keys.forEach((item) => {
                                item.active = false;
                            });
                        });
                    }
                })));
                return p.stepTimer.pipe(...this.playString(this.sample.value, this.samplePause$, keyMap, this.sampleState), (0, operators_1.take)(this.sample.value.length - 1), (0, operators_1.finalize)(() => {
                    this.$timeout(() => {
                        this.sampleState = 'stop';
                        this.sample.value.forEach((item) => {
                            item.correct = false;
                            item.active = false;
                        });
                        keys.map((item) => {
                            item.active = false;
                        });
                    });
                }));
            }
            this.sampleState = 'stop';
            return rxjs_1.NEVER;
        })).subscribe();
    }
    setSize(size) {
        this.data.size = size;
        this.destroy.next();
        this.initPiano();
    }
    mouseDown(item) {
        item.active = true;
        this.play.next(item);
    }
    mouseUp(item) {
        item.active = false;
    }
    mouseLeave(item) {
        item.active = false;
    }
    doHistory(state) {
        this.samplePlay.next('stop');
        switch (state) {
            case "pause": {
                this.historyPause$.next(true);
                break;
            }
            case "play": {
                this.historyPause$.next(false);
                if (this.historyState == 'stop') {
                    this.historyPlay.next('play');
                }
                break;
            }
            case "stop": {
                this.historyPlay.next('stop');
                break;
            }
        }
    }
    doSample(state) {
        this.historyPlay.next('stop');
        switch (state) {
            case "pause": {
                this.samplePause$.next(true);
                break;
            }
            case "play": {
                this.samplePause$.next(false);
                if (this.sampleState == 'stop') {
                    this.samplePlay.next('play');
                }
                break;
            }
            case "stop": {
                this.samplePlay.next('stop');
                break;
            }
        }
    }
    editHistory() {
        return __awaiter(this, void 0, void 0, function* () {
            this.historyPlay.next('stop');
            this.samplePlay.next('stop');
            this.disablePiano = true;
            const data = {
                history: this.history.map((item) => item.value).join('')
            };
            const result = yield this.ModalServiceFactory.open({
                id: 'piano-edit-history',
                component: "piano-edit-history",
                template: require('./editHistory.ng.html'),
                extraContext: {
                    existsCharacter: this.existsCharacter,
                    data: data
                }
            });
            if (result == 'save') {
                this.history = data.history.split(this.notesRegex).map((item) => {
                    return {
                        correct: false,
                        active: false,
                        value: item
                    };
                });
            }
            this.disablePiano = false;
        });
    }
    getPausableTimer(pause) {
        const complete$ = new rxjs_1.Subject();
        const pausableTimer$ = (0, rxjs_1.defer)(() => {
            let seconds = 0;
            return (0, rxjs_1.interval)(this.mainTemp).pipe((0, operators_1.startWith)(-1), (0, operators_1.withLatestFrom)(pause), (0, operators_1.filter)(([v, paused]) => !paused), (0, operators_1.map)((i) => {
                return seconds++;
            }), (0, operators_1.finalize)(() => {
                complete$.next(seconds);
            }));
        }).pipe((0, operators_1.share)());
        return {
            stepTimer: pausableTimer$, completeTimer: complete$
        };
    }
    editClear() {
        this.doHistory('stop');
        this.history = [];
    }
    samplesTermInput($vm, term) {
        $vm.show = true;
        if (term) {
            this.sample.emptySamples = true;
            this.samples.forEach((item) => {
                item.show = item.title.toLowerCase().indexOf(term.toLowerCase()) > -1;
                if (item.show && this.sample.emptySamples)
                    this.sample.emptySamples = false;
            });
        }
        else {
            this.sample.emptySamples = false;
            this.samples.forEach((item, index) => {
                item.show = index < 10;
            });
        }
    }
    selectSample(item) {
        this.doSample('stop');
        this.sample.term = '';
        this.sample.value = item.characters.split(this.notesRegex)
            .filter((item) => item.length > 0)
            .map((item) => {
            return {
                active: false,
                correct: false,
                value: item,
            };
        });
        this.sample.title = item.title;
        if (item.temp) {
            this.mainTemp = item.temp;
        }
        else {
            this.mainTemp = ng.copy(defaultTemp);
        }
        this.samples.forEach((item, index) => {
            item.show = index < 10;
        });
    }
    nextShowState() {
        switch (this.data.label) {
            case "keys": {
                this.data.label = 'notes';
                break;
            }
            case "notes": {
                this.data.label = 'none';
                break;
            }
            case "none": {
                this.data.label = 'keys';
                break;
            }
        }
    }
}
PianoCtrl.$inject = ['$scope', '$filter', '$timeout', 'ConfigService', 'ModalServiceFactory', 'SoundService'];
const appModule = ng.module('app');
appModule.component('gamePiano', {
    transclude: true,
    template: require("./game.ng.html"),
    controller: PianoCtrl,
    controllerAs: '$ctrl',
    bindings: {
        config: "<"
    }
});
appModule.component('pianoEditHistory', {
    bindings: {
        'modalInstance': '='
    },
    template: ['$attrs', ($attrs) => {
            return $attrs.modalTemplate ? unescape($attrs.modalTemplate) : require("./editHistory.ng.html");
        }],
    controller: editHistory_1.PianoEditHistoryCtrl,
    controllerAs: '$ctrl'
});
appModule.config(['WsServiceProvider', 'SoundServiceProvider', 'ConfigServiceProvider', (WsServiceProvider, SoundServiceProvider, ConfigServiceProvider) => {
        WsServiceProvider.setPrefix('piano/');
        ConfigServiceProvider.setDefaultConfig({
            cookie_show: '',
            dark_mode: 'no',
            sound_effects: true,
        });
        SoundServiceProvider.setSound(soundCache);
    }]);
