"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PianoEditHistoryCtrl = void 0;
const alert_1 = require("@src/app/components/alert");
class PianoEditHistoryCtrl extends alert_1.AlertComponentCtrl {
    constructor() {
        super(...arguments);
        this.data = this.$modalScope.data;
        this.validCharactersRegex = `[${this.$modalScope.existsCharacter.join('')}\\s\|\\[\\]]*`;
    }
    $onInit() {
        super.$onInit();
    }
    editFormSubmit() {
        var _a;
        if ((_a = this.editForm) === null || _a === void 0 ? void 0 : _a.$valid) {
            this.close('save');
        }
    }
}
exports.PianoEditHistoryCtrl = PianoEditHistoryCtrl;
